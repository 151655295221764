import React, { FunctionComponent } from 'react';
import Formatter from './Formatter';

const LogText: FunctionComponent<{ children: string }> = ({ children }) => {
  return (
    <div style={styles.details}>
      <Formatter>{children}</Formatter>
    </div>
  );
};

const styles = {
  details: {
    marginTop: '0.5em',
    padding: '1em',
    backgroundColor: '#1A1A1A',
    color: '#BEBCB4',
    fontFamily: 'monospace'
  }
};

export default LogText;
