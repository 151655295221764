import { combineReducers } from 'redux';
import authReducer from './authReducer';
import entitiesReducer from './entitiesReducer';
import errorReducer from './errorReducer';

export default combineReducers({
  entities: entitiesReducer,
  auth: authReducer,
  error: errorReducer
});
