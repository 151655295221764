import { CLEAR_ERRORS, ErrorActions, ErrorState, GET_ERRORS } from './types';

const initialState: ErrorState = {
  msg: {},
  status: null,
  id: null
};

const errorReducer = (state = initialState, action: ErrorActions): ErrorState => {
  switch (action.type) {
    case GET_ERRORS:
      return { ...state, ...action.payload };
    case CLEAR_ERRORS:
      return {
        msg: {},
        status: null,
        id: null
      };
    default:
      return state;
  }
};

export default errorReducer;
