import React, { ReactNode, useState } from 'react';
import './GenericContainerSidebar.css';
import { Link } from 'react-router-dom';
import GenericIcons from '../GenericIcons';

type Props = {
  children: ReactNode;
  img?: string;
  titleTopNav?: string;
  AppNavbar?: ReactNode;
  linkTopNav?: string;
  logout?: ReactNode;
  user?: string;
};

const GenericContainerSidebar = (props: Props) => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  return (
    <div className='container-Sidebar'>
      <div className='Sidebar-top-nav'>
        <div className='sidebar-image'>
          {isOpenSidebar && (
            <div onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
              <GenericIcons
                icon='arrowLeft'
                imageStyle={{
                  width: '30px',
                  height: '30px',
                  transform: isOpenSidebar ? 'rotate(180deg)' : '',
                  cursor: 'pointer',
                  position: 'absolute',
                  left: '20px',
                  opacity: 0.5,
                  top: '15px'
                }}
              />
            </div>
          )}
          <img src={props.img} alt='' />
        </div>
        <div className='title-sidebar'>
          <Link to={`${props.linkTopNav}`} className='test'>
            <span className='text-title-sidebar'>
              Gestión <strong>Inventario</strong>
            </span>
          </Link>
        </div>
      </div>
      <div className='Sidebar-bottom-container'>
        <div className={`left-sidebar ${isOpenSidebar ? 'sidebar-fade' : ''}`}>
          <div className='top'>
            <div className='top-welcome'>
              <GenericIcons
                icon='avatar'
                imageStyle={{
                  width: 32,
                  height: 32
                }}
              />
              <span className='top-welcome-user'>Bienvenido {props.user}</span>
            </div>
          </div>
          <div onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
            <GenericIcons
              icon='arrowLeft'
              imageStyle={{
                width: 30,
                height: 30,
                transform: isOpenSidebar ? 'rotate(0deg)' : '',
                cursor: 'pointer',
                position: 'absolute',
                top: '21px',
                right: '10px',
                opacity: 0.5
              }}
            />
          </div>
          <div className='middle-container'>
            <div className='top-middle'>{props.AppNavbar}</div>
            <div className='bottom-logout-middle'>
              <GenericIcons
                icon='logout'
                imageStyle={{
                  width: 20,
                  margin: 0,
                  height: 20
                }}
              />
              {props.logout}
            </div>
          </div>
          <div className='bottom'>
            <span className='title-botton-gestion'>Gestión Inventario by</span>
            <a href='https://datafy.cl/' target='_blank' rel='noopener noreferrer'>
              <GenericIcons
                icon='datafy'
                imageStyle={{
                  width: 73,
                  marginLeft: 8,
                  height: 26
                }}
              />
            </a>
          </div>
        </div>
        <div className='right-render'>{props.children}</div>
      </div>
    </div>
  );
};

export default GenericContainerSidebar;
