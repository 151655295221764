import * as Papa from 'papaparse';
import React, { ChangeEvent, FunctionComponent, useRef, useState } from 'react';
import { Input } from 'reactstrap';
import { Button, Icon } from 'semantic-ui-react';
import { popAlert, PopAlertType } from './PopAlert';
import GenericHeaderExportButton from '../components/generics/GenericHeaderExportButton';
import { SimpleStringObject } from '../types';

type FileReaderColumnObject = { name: string; required?: boolean; export?: boolean };
export type FileReaderColumnProps = { [key: string]: string | FileReaderColumnObject };
const isNullOrUndefined = (value: any) => value === null || value === undefined;

type Props = {
  columns?: FileReaderColumnProps;
  callback: (data: Array<{ [key: string]: string }>) => Promise<any>;
  dataInputName?: string; // NOTE: the name to show to the user in case of error
};

const FileReader: FunctionComponent<Props> = ({ columns = {}, callback, dataInputName }) => {
  const requiredColumns = Object.keys(columns).filter(
    (key) =>
      typeof columns[key] === 'string' ||
      (typeof columns[key] === 'object' &&
        (columns[key] as FileReaderColumnObject)?.required !== false)
  );

  const desiredColumns = Object.keys(columns).filter(
    (key) =>
      typeof columns[key] === 'object' &&
      (columns[key] as FileReaderColumnObject)?.required === false
  );

  const exportColumns = [];

  for (const key in columns) {
    const isRequired = (columns[key] as FileReaderColumnObject)?.required !== false;
    const isExport =
      // IF is explicitly true
      (columns[key] as FileReaderColumnObject)?.export === true ||
      // OR in CASE to be undefined or null, is export only if `isRequired` is also true
      (isNullOrUndefined((columns[key] as FileReaderColumnObject)?.export) && isRequired);

    if (typeof columns[key] === 'string') {
      exportColumns.push(columns[key]);
    } else if (typeof columns[key] === 'object' && isExport) {
      exportColumns.push((columns[key] as any).name);
    }
  }

  const columnMap: SimpleStringObject = {};
  const headerTemplatecolumnMap: SimpleStringObject = {};

  for (const key in columns) {
    if (typeof columns[key] === 'object' && (columns[key] as any).name) {
      columnMap[(columns[key] as any).name] = key;
      headerTemplatecolumnMap[key] = (columns[key] as any).name;
    } else {
      columnMap[columns[key] as string] = key;
      headerTemplatecolumnMap[key] = columns[key] as string;
    }
  }

  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCsvFile(event.target.files![0]);
  };

  const headerTemplate = [...exportColumns].map(
    (header) => (headerTemplatecolumnMap as any)[header] || header
  );

  const importCSV = () => {
    try {
      Papa.parse(csvFile!, {
        complete: updateData,
        header: true,
        transformHeader: (header: string) => {
          return (columnMap as any)[header] || header;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateData = async (result: Papa.ParseResult<any>) => {
    setLoading(true);
    const { data } = result;
    const lastData = data.pop();

    const uniqueFn = (c: string, idx: number, self: Array<string>) => self.indexOf(c) === idx;

    const classifiedRows = data.reduce(
      (acc: any, row: Array<{}>, idx: any) => {
        const notFoundRequiredColumns: any = requiredColumns.find((c: any) => !row[c]);
        const notFoundDesiredColumns = desiredColumns.find((c: any) => !row[c]);
        !notFoundRequiredColumns && acc.valids.push(row);
        notFoundRequiredColumns && acc.invalids.push(row);
        notFoundDesiredColumns && acc.warnings.push(row);
        notFoundRequiredColumns && acc.notFoundRequiredColumns.push(notFoundRequiredColumns);
        notFoundDesiredColumns && acc.notFoundDesiredColumns.push(notFoundDesiredColumns);
        notFoundRequiredColumns && acc.notFoundRequiredOnRows.push(idx + 2); // +2 beacuse idx start from 0 (then +1) plus the header row

        return acc;
      },
      {
        valids: [],
        invalids: [],
        warnings: [],
        notFoundRequiredColumns: [],
        notFoundDesiredColumns: [],
        notFoundRequiredOnRows: []
      }
    );

    classifiedRows.notFoundRequiredColumns =
      classifiedRows.notFoundRequiredColumns.filter(uniqueFn);
    classifiedRows.notFoundDesiredColumns = classifiedRows.notFoundDesiredColumns.filter(uniqueFn);

    if (classifiedRows.invalids.length > 0) {
      popAlert({
        type: PopAlertType.ERROR,
        title: `${dataInputName ? `Asegúrese de que esta cargando un ${dataInputName}.\n` : ''
          }El archivo no posee todas las columnas requeridas, revise e intente nuevamente por favor.`,
        details: `No se encontraron las columnas: ${classifiedRows.notFoundRequiredColumns
          } en las filas: ${classifiedRows.notFoundRequiredOnRows}.
Detalle (Primera fila con error):
${JSON.stringify(classifiedRows.invalids[0])}`
      });

      setLoading(false);
      return;
    }

    setLoading(false);

    try {
      setUploading(true);
      await callback(classifiedRows.valids);
      setUploading(false);

      // Reset values to null
      fileInput.current!.value = '';
      setCsvFile(null);
    } catch (error) {
      console.log(error);
      setUploading(false);

      // Reset values to null
      fileInput.current!.value = '';
      setCsvFile(null);
    }
  };

  return (
    <div>
      <h4>Ingresa los datos a partir de un archivo CSV</h4>

      <Input
        type='file'
        name='file'
        innerRef={fileInput}
        placeholder={'Lista de solicitudes'}
        accept='.csv'
        onChange={handleChange}
      />
      <GenericHeaderExportButton headers={headerTemplate} />

      <Button
        disabled={!csvFile}
        color='black'
        onClick={importCSV}
        style={{ display: 'block', marginTop: '1em' }}>
        <Icon name='upload' />
        Procesar Archivo
      </Button>

      {loading ? <h3>Leyendo datos...</h3> : uploading ? <h3>Procesando datos...</h3> : null}
    </div>
  );
};

export default FileReader;
