import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import { TipoUsuarioValues } from '../types';
import { Redirect } from 'react-router-dom';

interface IProps {
  auth: AuthState;
}

const AnonimousPage: FunctionComponent<IProps> = ({ auth }) => {
  const { isAuthenticated, user } = auth;
  const allowedUserTypes = [
    TipoUsuarioValues.Admin as string,
    TipoUsuarioValues.Operador as string,
    TipoUsuarioValues.Visualizador as string
  ];

  if (isAuthenticated) {
    if (allowedUserTypes.includes(user!.nombreTipoUsuario)) {
      return <Redirect to='/conteo-criticidad' />;
    }
  }

  return (
    <div style={{ marginInline: '2em' }}>
      <h1> Bienvenido! Por favor inicie sesión para empezar a utilizar la aplicación </h1>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(AnonimousPage);
