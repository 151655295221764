// This file is a helper for formularies elements which needs to be fetched from the server
import axios from 'axios';
import { storedTokenConfig } from './header';
import { cleanObjectToQueryString, loadQuery, objectToQueryString } from './utils';
type RequestMethod = 'post' | 'get' | 'put' | 'patch';

// Gets a generic entity
export const getEntity = async (
  endpoint: string,
  opt?: { GETPath?: string; query?: object | (() => Promise<any>); passwordLessToken?: string }
) => {
  const { GETPath = '', query, passwordLessToken } = opt || {};

  const localQuery = await loadQuery(query);
  const queryURL = objectToQueryString(localQuery);
  const localEndpoint = GETPath ? `${endpoint}/${GETPath}` : endpoint;
  try {
    const res = await axios.get(
      `/api/${localEndpoint}${queryURL}`,
      storedTokenConfig(passwordLessToken)
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

// Make Request
export const performUncatchedRequest = async ({
  endpoint,
  method = 'get',
  query = {},
  isBlob = false
}: {
  endpoint: string;
  method: RequestMethod;
  query?: any;
  isBlob?: boolean;
}) => {
  const queryURL = objectToQueryString(query);
  const config = storedTokenConfig();

  const res = await axios({
    url: `/api/${endpoint}${queryURL}`,
    headers: config.headers,
    method,
    responseType: isBlob ? 'blob' : undefined // 'json' is by default
  });
  return res;
};

// Gets a data from an endpoint
export const getUncatchEndpointData = async ({
  endpoint,
  query = {},
  isBlob = false
}: {
  endpoint: string;
  query?: any;
  isBlob?: boolean;
}) => {
  const res = await performUncatchedRequest({ endpoint, method: 'get', query, isBlob });
  return res.data;
};

export const getEndpointData = async ({
  endpoint,
  query = {},
  isBlob = false
}: {
  endpoint: string;
  query?: any;
  isBlob?: boolean;
}) => {
  try {
    return await getUncatchEndpointData({ endpoint, query, isBlob });
  } catch (err) {
    console.error(err);
  }
};

// Gets paginated data
// TODO: Discuss the use of query
export const fetchPaginatedData = async (
  endpoint: string,
  page = 1,
  pageSize = 10,
  filters?: { [key: string]: any }
) => {
  if (page <= 0) return null;

  const queryString = cleanObjectToQueryString({ page, pageSize, filters });

  try {
    const { data } = await axios.get(`/api/${endpoint}${queryString}`, storedTokenConfig());
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Gets data by a given limit until get all
export const fetchDataOnStream = async (
  endpoint: string,
  opt: {
    callback: (data: any) => void;
    GETPath?: string;
    query?: object;
    passwordLessToken?: string;
    limitRows: number;
    fetchByNRows: number;
    onParallel: number;
  }
) => {
  const {
    callback,
    GETPath = '',
    query = {},
    passwordLessToken,
    limitRows = 0,
    fetchByNRows = 0,
    onParallel = 1
  } = opt;

  if (onParallel < 1 || fetchByNRows < 0) {
    return;
  }

  const queryURL = objectToQueryString(query);
  const cicleRange = { skip: 0, limit: fetchByNRows };

  const totalData = [];
  let fetchPromises = [];

  const urlFetcher = (url: string) => axios.get(url, storedTokenConfig(passwordLessToken));

  try {
    const localEndpoint = GETPath ? `${endpoint}/${GETPath}` : endpoint;
    if (fetchByNRows === 0) {
      const { data } = await axios.get(
        `/api/${localEndpoint}${queryURL}`,
        storedTokenConfig(passwordLessToken)
      );

      return callback(data);
    }

    // Mientras los datos devueltos sean menor al limitRows
    while (true) {
      //const cicleRangeQueryString = objectToQueryString({ range: cicleRange });
      const totalQueryString = objectToQueryString({ ...query, range: cicleRange });
      const lastPromise = urlFetcher(`/api/${localEndpoint}${totalQueryString}`);
      fetchPromises.push(lastPromise);

      if (fetchPromises.length === onParallel) {
        const fetchedData: any[] = await Promise.all(fetchPromises);
        totalData.push(
          ...fetchedData.reduce(
            (acc, { data: d }) => (d && d.length > 0 ? acc.push(...d) && acc : acc),
            []
          )
        );

        // NOTE: Force to give a new array in every iteration, so the component will detect the change and will update
        callback([...totalData]);
        // reset paralelFetchedData
        fetchPromises = [];

        const { data: lastData } = await lastPromise;
        // At this point the data is loaded
        if (
          (limitRows > 0 && totalData.length >= limitRows) || // there are more rows than the limit asked for
          !lastData || // The last fetch doesn't get any rows
          lastData.length < fetchByNRows // the last fetch get less rows than asked for
        ) {
          break;
        }
      }

      cicleRange.skip += fetchByNRows;
    }

    return totalData;
  } catch (err) {
    callback([]);
    console.error(err);
  }
};
