import { AppGetState } from '../store';
import { getFromStorage, TOKEN } from '../utils/storage';

// Return a json header for 'application/json' content type
export const jsonHeaders = (): { headers: { [key: string]: string } } => {
  return {
    headers: {
      'Content-Type': 'application/json'
    }
  };
};

// Setup config/headers and token from the app's state
export const tokenConfig = (getState: AppGetState) => {
  const auth = getState().auth;
  const { passwordLessToken } = auth.impersonation || { passwordLessToken: undefined };

  const token = passwordLessToken || auth.token;
  const config = jsonHeaders();

  // Either use password-less token (if any) or stored one
  if (token) {
    config.headers['X-Auth-Token'] = token;
  }

  return config;
};

// Setup config/headers and token directly from the storage
export const storedTokenConfig = (passwordLessToken?: string) => {
  const token = passwordLessToken || getFromStorage(TOKEN);
  const config = jsonHeaders();

  if (token) {
    config.headers['X-Auth-Token'] = token;
  }

  return config;
};
