export const CCUTheme = {
  text: {
    primary: '#000',
    secondary: '#000'
  },
  background: {
    default: '#fff'
  },
  context: {
    background: '#cb4b16',
    text: '#000'
  },
  divider: {
    default: '#000'
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.50)',
    disabled: 'rgba(0,0,0,.12)'
  }
};

export const CCUThemeButtonCargarInventario = {
  color: ' #FFF',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  fontfFeatureSettings: 'clig off, liga off',
  lineHeight: '24px',
  borderRadius: '8px',
  padding: '12px 15px',
  border: 'none',
  background: '#64A70B',
  display: 'flex',
  alignItems: 'center'
};

export const CCUThemeButtonCargarConteo = {
  color: '#64A70B',
  textAlign: 'center',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '24px',
  borderRadius: '8px',
  padding: '12px 15px',
  border: '2px solid #64A70B',
  background: 'transparent',
  display: 'flex',
  alignItems: 'center'
};

export const customStyles = {
  table: {
    style: {
      border: '1px solid #BDBDBD'
    }
  },
  tableHeader: {
    style: {
      padding: '10px',
      border: '1px solid red'
    }
  },
  header: {
    style: {
      margin: '0px',
      padding: '0px'
    }
  },
  rows: {
    style: {
      minHeight: '42px',
      border: '1px solid var(--gray-4, #BDBDBD)',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#F2F2F2'
      }
    },
    headCells: {
      style: {
        backgroundColor: '#F2F2F2',
        color: '#828282'
      }
    }
  }
};
