import moment from 'moment';
import React, {
  Fragment,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { CSVLink } from 'react-csv';
import DataTable, { IDataTableConditionalRowStyles, createTheme } from 'react-data-table-component';
import { Alert, Button, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import DatePicker from 'reactstrap-date-picker';
import { Button as ButtonB, Icon } from 'semantic-ui-react';
import { FilterFields, TableColumn } from '../../entities/types';
import { BasicEntity, SimpleObject } from '../../types';
import { convertArrayOfObjectToFieldsMatrix, downloadCSV } from '../../utils/export';
import { getObjectFromStorage, addObjectToStorage, removeFromStorage } from '../../utils/storage';
import { specialFilter, filterObject, modificarOrdenColumnas } from '../../utils/utils';
import Pagination, { PaginationProps } from '../Pagination';
import {
  AdditionalTableActionsFunc,
  AdditionalTableProps,
  ColumnComponent,
  RowDisabledCriteria
} from '../types';
import { GenericFilterFormModal } from './GenericFilterForm';
import * as XLSX from 'xlsx';
import { customStyles } from './GenericThemes';

createTheme('invertedHeader', {
  context: {
    background: '#23272b',
    text: '#F8F8EB'
  }
});

type FileReaderColumnObject = { name: string; required?: boolean; export?: boolean };
const isNullOrUndefined = (value: any) => value === null || value === undefined;

const EmptyTableMessage: FunctionComponent<{}> = () => (
  <div style={{ marginTop: 10 }}>
    <Alert color='warning'>¡No existen registros por el momento!</Alert>
  </div>
);

const FilterComponent: FunctionComponent<{
  filterText: string;
  onChange: (e: any) => void;
  onKeyDown: (e: any) => void;
  onClear: () => void;
}> = ({ filterText, onChange, onKeyDown, onClear }) => (
  <>
    <InputGroup>
      <Input
        id='search'
        type='text'
        placeholder='Filtro general'
        value={filterText}
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
      <InputGroupAddon addonType='append'>
        <Button onClick={onClear}>&times;</Button>
      </InputGroupAddon>
    </InputGroup>
  </>
);

const CustomExport: FunctionComponent<{
  exportDataHandler: (when?: string) => Promise<any>;
  requireDate?: boolean;
  fileName?: string;
  columns?: SimpleObject;
}> = ({ exportDataHandler, requireDate = false, fileName, columns }) => {
  const [selectedDate, setSelectedDate] = useState(moment().local().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState<boolean>(false);

  const exportData = async () => {
    setLoading(true);
    if (requireDate && !selectedDate) {
      setLoading(false);
      alert('Seleccione una fecha para generar el reporte');
      return;
    }

    const csvBlob = await exportDataHandler(
      requireDate ? moment(selectedDate).local().format('YYYY-MM-DD') : undefined
    );

    if (csvBlob instanceof Blob && !csvBlob.size) {
      setLoading(false);
      return alert(
        requireDate
          ? 'No se encontraron registros para la fecha especificada'
          : 'No se encontraron registros'
      );
    }

    if (csvBlob instanceof Blob || !columns) {
      downloadCSV(csvBlob, fileName);
      setLoading(false);
      return;
    }

    const processedColumns: Record<string, string> = {};

    for (const key in columns) {
      const value = columns[key];
      if (typeof value === 'string') {
        processedColumns[key] = value;
      } else if (typeof value === 'object' && value.export) {
        if ('name' in value && typeof value.name === 'string') {
          processedColumns[key] = value.name;
        }
      }
    }

    const newBlob = modificarOrdenColumnas(csvBlob, processedColumns);
    downloadCSV(newBlob, fileName);
    setLoading(false);
    return;
  };

  return (
    <>
      {requireDate && (
        <div>
          <Label>Fecha del Reporte</Label>
          <DatePicker value={selectedDate} onChange={(date: string) => setSelectedDate(date)} />
        </div>
      )}
      {loading && <h1>Cargando...</h1>}
      <ButtonB color='blue' onClick={exportData}>
        <Icon name='download' />
        Exportar
      </ButtonB>
    </>
  );
};

const StoredFormFilterManager: FunctionComponent<{
  storedFilterName: string;
  storedFormFilter: SimpleObject;
  refreshFunction: any;
}> = ({ storedFilterName, storedFormFilter, refreshFunction }) => {
  const objectToData = (simpleObject: SimpleObject) => ({
    header: ['filtro', 'valor'],
    rows: simpleObject
      ? Object.entries(simpleObject).filter(([_, v]) => v != null && v !== '')
      : [[]]
  });

  const data = objectToData(storedFormFilter);
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(objectToData(storedFormFilter));
  }, [storedFormFilter]);

  const onCleanStorageSingleItem = (itemName: string) => {
    const data = getObjectFromStorage(storedFilterName);
    removeFromStorage(storedFilterName);

    delete data[itemName];
    addObjectToStorage(storedFilterName, data);
    refreshFunction();
  };

  return (
    <Fragment>
      {tableData.rows && tableData.rows[0]?.length > 0 && (
        <Fragment>
          {tableData.rows.map((item, i) => (
            <button
              key={i}
              style={{
                display: 'flex',
                height: '34px',
                padding: '2px 10px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
                backgroundColor: 'transparent',
                borderRadius: '8px',
                border: '2px solid var(--ccu-verde-oscuro, #205C40)'
              }}
              onClick={() => onCleanStorageSingleItem(item[0])}>
              <p
                style={{
                  color: 'var(--ccu-verde-oscuro, #205C40)',
                  textAlign: 'center',
                  fontFamily: ' Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px'
                }}>
                {item[1]}
              </p>
              X
            </button>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

export const ExportCSV: FunctionComponent<{ onExport: () => string[][] }> = ({ onExport }) => (
  <CSVLink
    style={{
      display: 'inline-block',
      padding: '10px 20px',
      marginInline: '1em',
      fontSize: '16px',
      fontWeight: 'bold',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#4CAF50',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer'
    }}
    data={onExport()}
    filename={`export_${new Date().toISOString().split('T')[0]}.csv`}
    separator={';'}>
    <Icon name={'download'} />
  </CSVLink>
);

const Export: FunctionComponent<{ onExport: () => string[][] }> = ({ onExport }) => {
  const handleExport = () => {
    const data = onExport();
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, sheet, 'Datos');
    XLSX.writeFile(workbook, 'export_' + new Date().toISOString().split('T')[0] + '.xlsx');
  };

  return (
    <button
      onClick={handleExport}
      style={{
        display: 'flex',
        padding: '5px 15px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: 'transparent',
        borderRadius: '8px',
        border: '2px solid #007DBA',
        color: '#007DBA',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px'
      }}>
      Exportar Excel
    </button>
  );
};

// Rows per page in server side
// TODO: change this once the endpoint let us set 'ROWS_PER_PAGE' from client side
const ROWS_PER_PAGE = 20;
const DEFAULT_PAGE = 1;

type Props<T> = {
  columns: TableColumn<T>[];
  tableData: BasicEntity[];
  fetchData: (pageNumber?: number, pageSize?: number, filters?: any) => Promise<any>;
  filterFields?: FilterFields[];
  addAction?: JSX.Element;
  addMultiLineAction?: JSX.Element;
  deleteAction?: () => (getSelectedData: () => { ids: string[]; refs: string[] }) => JSX.Element;
  secondaryAction?: AdditionalTableActionsFunc<T>;
  allowedActions?: Partial<{
    add: boolean;
    multiLineForm: boolean;
    delete: boolean;
    export: boolean;
    select: boolean;
    singleSelect: boolean;
  }>;
  tableName?: string;
  serverSidePagination?: boolean;
  onSelectedRowsChange?: (rows: T[]) => void;
  liveFilter?: boolean;
  sharedFilterName?: string;
  selectablePageOnly?: boolean;
  rowDisabledCriteria?: RowDisabledCriteria<T>;
  rowColumnReference?: string;
  expandableRowsComponent?: () => ReactNode;
  columnComponent?: ColumnComponent<T>;
  additionalTableProps?: AdditionalTableProps<T>;
  customExport?: {
    exportDataHandler: (when?: string) => Promise<Blob>;
    requireDate?: boolean;
    fileName?: string;
    columns?: SimpleObject;
  };
  selectedSingleEntity?: (row: T, event: Event) => any;
  isAdmin?: boolean;
};

const GenericTable = <T,>({
  // Required
  columns,
  tableData,
  fetchData,
  // Optionals
  filterFields,
  addAction,
  addMultiLineAction,
  deleteAction,
  secondaryAction,
  allowedActions,
  tableName = '',
  serverSidePagination = false,
  onSelectedRowsChange,
  liveFilter = false,
  sharedFilterName,
  selectablePageOnly = false,
  rowDisabledCriteria,
  rowColumnReference = '',
  expandableRowsComponent,
  columnComponent,
  additionalTableProps,
  customExport,
  selectedSingleEntity,
  isAdmin
}: Props<T>) => {
  // === Initialize states and its 'setters functions' sub-components ===
  // NOTE: useState(initialValue) returns an 'state' var and an asociated 'setter' function
  // FilterText text
  const [filterText, setFilterText] = useState<string>('');
  const [preFilterText, setPreFilterText] = useState<string>('');

  // Pagination state
  const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);

  // Server side pagination
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<{ totalRows: number; availablePages: number }>();

  // Server side filter
  const getStoredSharedFilter = () => {
    if (filterFields) {
      const validFilters = filterFields.map((ff) => ff.selector);
      const sharedFilter = getObjectFromStorage(sharedFilterName || '');
      return filterObject(sharedFilter, validFilters);
    }
    return null;
  };

  const sharedFilter = getStoredSharedFilter();
  // TODO: Add only the filters that match filterfields
  const [formFilters, setFormFilters] = useState(sharedFilter || {});

  // Selected rows
  const [toggleCleared, setToggleCleared] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState([]);

  //Selected IDEntity
  const [idEntity, setIdEntity] = useState('');

  const columnsSelector = useMemo(
    () => columns.map((c) => (c.format ? c.format : c.selector)),
    [columns]
  );

  const {
    add: canAdd,
    multiLineForm: canMultiLine,
    delete: canDelete,
    export: canExport,
    select: canSelect,
    singleSelect
  } = {
    add: true,
    multiLineForm: true,
    delete: true,
    export: false,
    select: false,
    singleSelect: false,
    ...allowedActions
  };

  const tableColumns = useMemo(
    () =>
      columnComponent
        ? columnComponent.begin
          ? [{ ...columnComponent.column }, ...columns]
          : [...columns, { ...columnComponent.column }]
        : columns,
    [columns, columnComponent, tableData]
  );

  const fetchDataPage = useMemo(
    () => async (page: number) => {
      setLoading(true);

      const defaultPageSize = 10;
      if (serverSidePagination) {
        const { availablePages, totalItemsCount } = await fetchData(
          page,
          defaultPageSize,
          formFilters
        );
        setPagination({ totalRows: totalItemsCount, availablePages });
      } else {
        await fetchData(0, 0, formFilters);
      }

      setLoading(false);
    },
    [fetchData, serverSidePagination, formFilters]
  );

  // ComponentDidUpdate (dataTable)
  useEffect(() => {
    setToggleCleared((toggleCleared) => !toggleCleared);
  }, [tableData]);

  // ComponentDidUpdate (fetch data applying the new filters)
  useEffect(() => {
    // Reset pagination
    setResetPaginationToggle((resetPaginationToggle) => !resetPaginationToggle);

    // Fetch data
    fetchDataPage(DEFAULT_PAGE);
  }, [formFilters, fetchDataPage]);

  // Filter items by checking if filterText is contained into the item's joined fields
  const filteredItems = useMemo(
    () => specialFilter(tableData, columnsSelector, filterText),
    [tableData, columnsSelector, filterText]
  );

  // Fn = Create a callback for row selection
  const handleRowSelected = useCallback(
    (state) => {
      setSelectedRows(state.selectedRows);
      onSelectedRowsChange!(state.selectedRows);
    },
    [onSelectedRowsChange]
  );

  // Fn = Create filter component for subheader
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText || preFilterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setPreFilterText('');
        setFilterText('');
      }
    };

    const onChange = (e: any) => {
      setPreFilterText(e.target.value);
      if (liveFilter) setFilterText(e.target.value);
    };

    const onKeyDown = (e: any) => {
      if (e.key === 'Enter') {
        setPreFilterText(e.target.value);
        setFilterText(e.target.value);
      }
    };

    return (
      <FilterComponent
        onChange={onChange}
        onKeyDown={onKeyDown}
        onClear={handleClear}
        filterText={preFilterText}
      />
    );
  }, [filterText, preFilterText, liveFilter, resetPaginationToggle]);

  const refreshStoredFilter = () => {
    const sharedFilter = getStoredSharedFilter();
    setFormFilters(sharedFilter || {});
  };

  const renderActions = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      {/* TODO: temporary Change, add again 'serverSidePagination &&' at the begining of the IF */}
      <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: ' center' }}>
        {filterFields && filterFields.length > 0 && (
          <GenericFilterFormModal
            filterFormFields={filterFields}
            onSubmit={applyServerSideFilter}
            isAdmin={!!isAdmin}
          />
        )}
        {sharedFilterName && (
          <StoredFormFilterManager
            storedFilterName={sharedFilterName}
            storedFormFilter={formFilters}
            refreshFunction={refreshStoredFilter}
          />
        )}
      </div>

      <div>{canMultiLine && addMultiLineAction}</div>

      <div style={{ display: 'flex', gap: ' 10px ' }}>
        {canExport ? (
          customExport ? (
            <CustomExport
              exportDataHandler={customExport.exportDataHandler}
              requireDate={customExport.requireDate}
              fileName={customExport.fileName}
              columns={customExport.columns}
            />
          ) : (
            <Export
              onExport={() =>
                convertArrayOfObjectToFieldsMatrix(
                  filteredItems,
                  columnsSelector,
                  columns.map((c: any) => c.name)
                )
              }
            />
          )
        ) : null}

        {canAdd && addAction}
      </div>
    </div>
  );

  // Fn = create a component to delete selected fields
  const contextActions = useMemo(() => {
    const getSelectedData = () => {
      const idColumn = '_id';

      // Show the idColumn if the rowColumnReference was not provided
      const rcr = rowColumnReference || idColumn;

      // Function to select the data to show (depending on if it has data)
      const selectRowColumnFn = (r: any) => r[rcr] || r[idColumn];

      // Getting the rows id (to work on it)
      // Getting the rows reference column (to show as message)
      const { refs, ids } = selectedRows.reduce(
        (prev, curr) => ({
          refs: [...prev.refs, selectRowColumnFn(curr)],
          ids: [...prev.ids, curr[idColumn]]
        }),
        { refs: [], ids: [] } as { refs: string[]; ids: string[] }
      );

      return { ids, refs };
    };
    return (
      <div style={{ display: 'flex' }}>
        {secondaryAction && secondaryAction({ selected: selectedRows })}
        {canDelete && deleteAction && deleteAction()(getSelectedData)}
        {/* TODO: Check this file in 6e6d967f0c90f7c8c45609174791595ae8950c46
         * REALLY STRANGE ERROR:
         * If I execute deleteAction={this.deleteButton()} in GenericCRUD and then
         * deleteAction(getSelectedData) in here, the 'secondaryAction' button cannot
         * refresh its own state.
         * */}
      </div>
    );
  }, [selectedRows, deleteAction, canDelete, rowColumnReference, secondaryAction]);

  const serverSidePaginationOptions = serverSidePagination
    ? {
        paginationTotalRows: pagination?.totalRows,
        paginationServer: true,
        onChangePage: fetchDataPage,
        paginationRowsPerPageOptions: [ROWS_PER_PAGE],
        paginationPerPage: ROWS_PER_PAGE
      }
    : {};

  const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: !serverSidePagination,
    selectAllRowsItemText: 'Todos'
  };

  const applyServerSideFilter = useMemo(
    () => (formFields: { [key: string]: any }) => {
      // set fields
      setFormFilters(formFields);
      if (sharedFilterName) {
        addObjectToStorage(sharedFilterName, formFields);
      }
    },
    [sharedFilterName]
  );

  const onRowClicked = (row: T & BasicEntity, event: Event) => {
    if (selectedSingleEntity) {
      selectedSingleEntity(row, event);
    }
    setIdEntity(row._id);
  };

  const RowStyles = (): IDataTableConditionalRowStyles[] => {
    const conditionalRowStyles = [
      {
        when: (row: any) => row._id === idEntity,
        style: {
          backgroundColor: '#F7FBF3',
          border: '2px solid var(--Gray-4, #BDBDBD)'
        }
      }
    ];
    return conditionalRowStyles;
  };

  return (
    <div>
      <h2 className='title-DataTable'>{tableName.toLocaleUpperCase()} </h2>
      {renderActions()}
      <DataTable
        {...{
          columns: tableColumns,
          data: filteredItems,
          theme: 'invertedHeader',
          conditionalRowStyles: RowStyles(),
          customStyles: customStyles,
          pagination: true,
          paginationResetDefaultPage: resetPaginationToggle, // optionally, a hook to reset pagination to page 1
          progressPending: loading,
          subHeader: true,
          subHeaderComponent: !serverSidePagination ? subHeaderComponentMemo : null,
          selectableRows: canSelect,
          pointerOnHover: singleSelect,
          onRowClicked,
          selectableRowDisabled: rowDisabledCriteria,
          persistTableHead: true,
          contextActions,
          onSelectedRowsChange: onSelectedRowsChange && handleRowSelected,
          clearSelectedRows: toggleCleared,
          striped: true,
          responsive: true,
          expandableRows: expandableRowsComponent ? true : false,
          expandOnRowClicked: expandableRowsComponent ? true : false,
          selectableRowsVisibleOnly: selectablePageOnly,
          paginationComponentOptions: paginationOptions,
          paginationComponent: serverSidePagination
            ? (props: PaginationProps) => (
                <Pagination
                  {...props}
                  availablePages={pagination ? pagination.availablePages : 0}
                />
              )
            : undefined,
          expandableRowsComponent: expandableRowsComponent && expandableRowsComponent(),
          noDataComponent: <EmptyTableMessage />,
          ...{ ...additionalTableProps, ...serverSidePaginationOptions }
        }}
      />
    </div>
  );
};

export default GenericTable;
