// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

// TODO: Add style
const NotFoundPage: FunctionComponent<{}> = () => (
  <div>
    <h3>404 Page Not Found</h3>
    <p>
      Go back to{' '}
      <Link className='bold' to='/'>
        Home
      </Link>
    </p>
  </div>
);

export default NotFoundPage;
