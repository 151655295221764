import React from 'react';
import * as E from '../entities';
import * as R from '../entities/reports';
import { BasicEntity, QueryNameValues, ReporteZonaCategoriaSKU } from '../types';
import { cleanTextDate } from '../utils/utils';
// import Q from '../entities/queries';
import { getEndpointData } from '../utils/request';
import { generateCRUD } from './CRUDGenerator';
import { filterFieldReporteCategoria } from '../entities/reports/ReporteCategoria';
import { Fields } from '../entities/types';

import { SKU_COLUMNS } from '../entities/SKUEntity';
import { PE_COLUMNS } from '../entities/PerfilEnvejecimientoEntity';
import { DV_COLUMNS } from '../entities/DiasVentaEntity';
import { DuplicateDocMultiForm } from './SecondaryButton';
import GenericIcons from './generics/GenericIcons';

// Component Example
/* export const OrdenesRetiroAnuladas = generateCRUD({
 *   webEntity: {
 *     ...E.OrdenEntity,
 *     tableColumns: canceledReturnOrderColumns,
 *     filterFields: []
 *   },
 *   title: 'ordenes retiro anuladas',
 *   GETPath: 'byOrderFilters',
 *   query: Q.ordenesEnEstadoV2(OrderStatus.Canceled),
 *   serverSidePagination: true,
 *   additionalTableActions: ({ selected }) => (
 *     <div>
 *       <RevertReturnOrderButton selected={selected} refreshStatus={OrderStatus.Canceled} />
 *     </div>
 *   ),
 *   columnComponent: {
 *     begin: true,
 *     column: {
 *       cell: (row) => <OrdenDetails orden={row} />,
 *       allowOverflow: true,
 *       button: true
 *     }
 *   },
 *   customExport: {
 *     exportDataHandler: async () =>
 *       await getEndpointData({
 *         endpoint: 'v2/ordenes/export/returnOrderAt',
 *         query: { status: OrderStatus.Canceled },
 *         isBlob: true
 *       })
 *   },
 *   allowedActions: { export: true, delete: false, add: false, select: { enable: true } },
 *   style: {}
 * }); */

// Mantenedores
export const UsuarioCRUD = generateCRUD({
  webEntity: E.UsuarioEntity,
  title: 'Administracion Usuario',
  allowedActions: {
    add: true,
    export: true,
    multiLineForm: false,
    delete: true,
    edit: true,
    select: { enable: true }
  }
});

export const TipoUsuarioCRUD = generateCRUD({
  webEntity: E.TipoUsuarioEntity,
  title: 'Administracion Tipo Usuario',
  allowedActions: {
    export: true,
    add: false,
    multiLineForm: false,
    delete: true,
    edit: false,
    select: { enable: true }
  }
});

export const EstadoDocumentoCRUD = generateCRUD({
  webEntity: E.EstadoDocumentoEntity,
  title: 'Administracion Estado Documento',
  allowedActions: {
    export: true,
    delete: true,
    edit: false,
    add: false,
    multiLineForm: false,
    select: { enable: true }
  }
});

export const SkuCRUD = generateCRUD({
  webEntity: E.SkuEntity,
  title: 'Administrar SKU',
  serverSidePagination: true,
  sharedFilterName: 'SharedFilterSku',
  customExport: {
    columns: SKU_COLUMNS,
    fileName: 'mantenedorSku',
    exportDataHandler: async () =>
      await getEndpointData({
        endpoint: 'sku/export',
        isBlob: false
      })
  },
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: true,
    multiLineForm: false,
    select: { enable: true }
  }
});

export const SkuFactorCRUD = generateCRUD({
  webEntity: E.SkuFactorEntity,
  title: 'Administrar SKU Factor',
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: true,
    multiLineForm: false,
    select: { enable: true }
  }
});

export const PerfilEnvejecimientoCRUD = generateCRUD({
  webEntity: E.PerfilEnvejecimientoEntity,
  title: 'Administrar Perfil Envejecimiento',
  serverSidePagination: true,
  sharedFilterName: 'SharedFilterPerfilEnvejecimiento', //
  customExport: {
    columns: PE_COLUMNS,
    fileName: 'mantenedorPerfilEnvejecimiento',
    exportDataHandler: async () =>
      await getEndpointData({
        endpoint: 'perfilEnvejecimiento/export',
        isBlob: false
      })
  },
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: true,
    multiLineForm: false,
    select: { enable: true }
  }
});

export const DiasVentaCRUD = generateCRUD({
  webEntity: E.DiasVentaEntity,
  title: 'Administrar Dias Venta',
  sharedFilterName: 'SharedFilterDiasVentas',
  serverSidePagination: true,
  customExport: {
    columns: DV_COLUMNS,
    fileName: 'mantenedorDiasVenta',
    exportDataHandler: async () =>
      await getEndpointData({
        endpoint: 'diasVenta/export',
        isBlob: false
      })
  },
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: true,
    multiLineForm: false,
    select: { enable: true }
  }
});

export const CentroInventarioCRUD = generateCRUD({
  webEntity: E.CentroInventarioEntity,
  title: 'Administracion Centros',
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: true,
    multiLineForm: false,
    select: { enable: true }
  }
});

export const ZonasOperadoresCRUD = generateCRUD({
  webEntity: E.ZonasOperadorEntity,
  title: 'Administrar Bodegas por Operador',
  sharedFilterName: 'reportFilters',
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: true,
    multiLineForm: false,
    select: { enable: true }
  }
});

export const ActivosCRUD = generateCRUD({
  webEntity: E.ActivoEntity,
  title: 'Administracion Activos',
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: true,
    multiLineForm: false,
    select: { enable: true }
  }
});

export const TipoActivosCRUD = generateCRUD({
  webEntity: E.TipoActivoEntity,
  title: 'Administracion Tipo Activos',
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: true,
    multiLineForm: false,
    select: { enable: true }
  }
});

export const NomenclaturasCRUD = generateCRUD({
  webEntity: E.NomenclaturaEntity,
  title: 'Administracion Nomenclaturas',
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: true,
    multiLineForm: false,
    select: { enable: true }
  }
});

export const RelacionEnvaseCasilleroCRUD = generateCRUD({
  webEntity: E.RelacionEnvaseCasilleroEntity,
  title: 'Administracion Relacion Envase-Casillero',
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: true,
    multiLineForm: false,
    select: { enable: true }
  }
});

export const InventarioCriticidadCRUD = generateCRUD({
  webEntity: E.InventarioCriticidadEntity,
  allowedActions: {
    export: false,
    delete: false,
    edit: true,
    add: false,
    multiLineForm: false,
    select: { enable: false }
  }
});

export const DocumentoCriticidadVisualizer = generateCRUD({
  webEntity: E.DocumentoCriticidadEntity,
  title: 'Documentos',
  allowedActions: {
    export: true,
    delete: false,
    edit: true,
    add: false,
    multiLineForm: false,
    select: { enable: false, single: true }
  }
});

export const InventarioCriticidadDependent = generateCRUD({
  webEntity: { ...E.InventarioCriticidadEntity, filterFields: [] },
  dummy: true,
  title: '',
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: false,
    multiLineForm: false,
    select: { enable: true }
  }
});

export const DocumentoEnvasesVisualizer = generateCRUD({
  webEntity: E.DocumentoEnvasesEntity,
  title: 'Documentos',
  additionalTableActions: ({ selected }) => (
    <DuplicateDocMultiForm
      selected={selected}
      endpoint='inventarioEnvases'
      endpointNameMultiInput='documentosEnvases'
      fields={E.DocumentoEnvasesEntity.fields!}
      entityName={E.DocumentoEnvasesEntity.name}
    />
  ),
  allowedActions: {
    export: true,
    delete: false,
    edit: true,
    add: false,
    multiLineForm: true,
    select: { enable: true, single: true, pageOnly: true }
  }
});

export const InventarioEnvasesDependent = generateCRUD({
  webEntity: { ...E.InventarioEnvasesEntity, filterFields: [] },
  dummy: true,
  title: '',
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: false,
    select: { enable: true }
  }
});

export const DocumentoPalletVisualizer = generateCRUD({
  webEntity: E.DocumentoPalletEntity,
  title: 'Documentos',
  additionalTableActions: ({ selected }) => (
    <DuplicateDocMultiForm
      selected={selected}
      endpoint='inventarioPallet'
      endpointNameMultiInput='documentosPallet'
      fields={E.DocumentoPalletEntity.fields!}
      entityName={E.DocumentoPalletEntity.name}
    />
  ),
  allowedActions: {
    export: true,
    delete: false,
    edit: true,
    add: false,
    multiLineForm: true,
    select: { enable: true, single: true, pageOnly: true }
  }
});

export const InventarioPalletDependent = generateCRUD({
  webEntity: { ...E.InventarioPalletEntity, filterFields: [] },
  dummy: true,
  title: '',
  allowedActions: {
    export: true,
    delete: true,
    edit: true,
    add: false,
    select: { enable: true }
  }
});

const DEFAULT_DATE = cleanTextDate(new Date());
const reportSortFunction = (rows: any, selector: string = 'orden', direction: string = 'asc') => {
  rows.sort((a: any, b: any) => {
    const factor = direction === 'asc' ? 1 : -1;

    if (a.orden < b.orden) {
      return -1;
    } else if (a.orden > b.orden) {
      return 1;
    } else {
      return a[selector] > b[selector] ? 1 * factor : a[selector] < b[selector] ? -1 * factor : 0;
    }
  });
  return rows.slice(0);
};

const conditionalRowStyles = [
  {
    when: (row: any) => row.orden === 1,
    style: {
      fontSize: '1.2em',
      fontWeight: 'bolder !important',
      backgroundColor: '#DADADA'
    }
  },
  {
    when: (row: any) => row.orden === 2,
    style: {
      color: 'white',
      fontWeight: 'bolder !important',
      fontSize: '1.2em',
      backgroundColor: '#757575'
    }
  }
];

const additionalReportTableOptions = {
  preProcessEntityList: reportSortFunction,
  additionalTableProps: {
    sortFunction: reportSortFunction,
    conditionalRowStyles: conditionalRowStyles
  }
};

export const ReporteCategoriaConteoMonto = generateCRUD({
  webEntity: {
    ...R.ReporteCategoria,
    filterFields: filterFieldReporteCategoria('conteoMonto') as Fields[]
  },
  title: 'Reporte Categoria Conteo Monto',
  sharedFilterName: 'reportFilters',
  query: {
    queryName: QueryNameValues.reporteCategoriaConteoMonto,
    fechaInventario: DEFAULT_DATE
  },
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    multiLineForm: false,
    select: { enable: false }
  },
  preProcessEntityList: (data: Array<ReporteZonaCategoriaSKU>) => {
    const sortedData = reportSortFunction(data);
    return sortedData.filter((row: ReporteZonaCategoriaSKU) => row.orden !== 1);
  },
  additionalTableProps: additionalReportTableOptions.additionalTableProps
});

export const ReporteCategoriaConteoCantidad = generateCRUD({
  webEntity: {
    ...R.ReporteCategoria,
    filterFields: filterFieldReporteCategoria('conteoCantidad') as Fields[]
  },
  title: 'Reporte Categoria Conteo Cantidad',
  sharedFilterName: 'reportFilters',
  query: {
    queryName: QueryNameValues.reporteCategoriaConteoCantidad,
    fechaInventario: DEFAULT_DATE
  },
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    multiLineForm: false,
    select: { enable: false }
  },
  preProcessEntityList: (data: Array<ReporteZonaCategoriaSKU>) => {
    const sortedData = reportSortFunction(data);
    return sortedData.filter((row: ReporteZonaCategoriaSKU) => row.orden !== 1);
  },
  additionalTableProps: additionalReportTableOptions.additionalTableProps
});

export const ReporteZonaConteoMonto = generateCRUD({
  webEntity: R.ReporteZona,
  title: 'Reporte Zona Conteo Monto',
  sharedFilterName: 'reportFilters',
  query: {
    queryName: QueryNameValues.reporteZonaConetoMonto,
    fechaInventario: DEFAULT_DATE
  },
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    multiLineForm: false,
    select: { enable: false }
  },
  ...additionalReportTableOptions
});

export const ReporteZonaConteoCantidad = generateCRUD({
  webEntity: R.ReporteZona,
  title: 'Reporte Zona Conteo Cantidad',
  sharedFilterName: 'reportFilters',
  query: {
    queryName: QueryNameValues.reporteZonaConetoCantidad,
    fechaInventario: DEFAULT_DATE
  },
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    multiLineForm: false,
    select: { enable: false }
  },
  ...additionalReportTableOptions
});

export const ReporteSKUMonto = generateCRUD({
  webEntity: R.ReporteSKU,
  title: 'Reporte SKU Monto',
  sharedFilterName: 'reportFilters',
  query: {
    queryName: QueryNameValues.reporteSKUMonto,
    fechaInventario: DEFAULT_DATE
  },
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    multiLineForm: false,
    select: { enable: false }
  },
  ...additionalReportTableOptions
});

export const ReporteSKUCantidad = generateCRUD({
  webEntity: R.ReporteSKU,
  title: 'Reporte SKU Cantidad',
  sharedFilterName: 'reportFilters',
  query: {
    queryName: QueryNameValues.reporteSKUCantidad,
    fechaInventario: DEFAULT_DATE
  },
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    multiLineForm: false,
    select: { enable: false }
  },
  ...additionalReportTableOptions
});

export const ReporteVencimientoConteoCantidad = generateCRUD({
  webEntity: R.ReporteVencimiento,
  title: 'Reporte Vencimiento Conteo Cantidad',
  sharedFilterName: 'reportFilters',
  query: {
    queryName: QueryNameValues.reporteVencimientoConteoCantidad,
    fechaInventario: DEFAULT_DATE
  },
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    multiLineForm: false,
    select: { enable: false }
  },
  ...additionalReportTableOptions
});

export const ReporteVencimientoConteoMonto = generateCRUD({
  webEntity: R.ReporteVencimiento,
  title: 'Reporte Vencimiento Conteo Monto',
  sharedFilterName: 'reportFilters',
  query: {
    queryName: QueryNameValues.reporteVencimientoConteoMonto,
    fechaInventario: DEFAULT_DATE
  },
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    multiLineForm: false,
    select: { enable: false }
  },
  ...additionalReportTableOptions
});

export const ReporteInventarioCriticidad = generateCRUD({
  webEntity: R.ReporteInventarioCriticidad,
  title: 'Reporte Inventario Criticidad',
  sharedFilterName: 'reportFilters',
  query: {
    queryName: QueryNameValues.reporteInventarioCriticidad,
    fechaInventario: DEFAULT_DATE
  },
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    multiLineForm: false,
    select: { enable: false }
  }
});

// TODO: Una vez que los filtros funcionen en este componente, pasale el E.ConteoCriticidadLogEntity tal cual
export const ReporteConteoCriticidadLog = generateCRUD({
  webEntity: { ...E.ConteoCriticidadLogEntity, filterFields: [] },
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    multiLineForm: false,
    select: { enable: false }
  }
});

export const ReporteInconsistenciaFechaLog = generateCRUD({
  webEntity: E.InconsistenciaFechaLogEntity,
  sharedFilterName: 'reportFilters',
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    multiLineForm: false,
    select: { enable: false }
  }
});
