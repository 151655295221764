import { addToStorage, getFromStorage, removeFromStorage, TOKEN } from '../utils/storage';
import {
  AuthActions,
  AuthState,
  AUTH_ERROR,
  IMPERSONATE_CLEAR,
  IMPERSONATE_LOADED,
  IMPERSONATE_LOADING,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  USER_LOADED,
  USER_LOADING
} from './types';

const initialState: AuthState = {
  token: getFromStorage(TOKEN),
  isAuthenticated: false,
  isLoading: true,
  user: null,
  impersonation: null
};

const authReducer = (state = initialState, action: AuthActions): AuthState => {
  switch (action.type) {
    case USER_LOADING:
    case IMPERSONATE_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload
      };
    case IMPERSONATE_LOADED:
      return {
        ...state,
        isLoading: false,
        impersonation: action.payload
      };
    case IMPERSONATE_CLEAR:
      return { ...state, impersonation: null };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      addToStorage(TOKEN, action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      removeFromStorage(TOKEN);
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false
      };
    default:
      return state;
  }
};

export default authReducer;
