import { TipoActivo } from '../types';
import { FieldTypes, WebEntity } from './types';

const TipoActivoEntity: WebEntity<TipoActivo> = {
  name: 'tipoActivo',
  endpoint: 'tipoActivos',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: 'idTipoActivo'
    },
    {
      name: 'Descripcion',
      selector: 'descripcionTipoActivo',
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Descripcion',
      selector: 'descripcionTipoActivo',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'ID Tipo Activo',
      selector: 'idTipoActivo',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Descripcion',
      selector: 'descripcionTipoActivo',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default TipoActivoEntity;
