import React, { useEffect, useState } from 'react';
import './GenericDropdownMenu.css';
import { useHistory } from 'react-router-dom';
import { CURRENT_PAGE_KEY, getFromStorage, addToStorage } from '../../../utils/storage';
import GenericIcons from '../GenericIcons';

export interface IMenuIten {
  id: number;
  label: string;
  icon?: string;
  img?: string;
  href?: string;
  children?: IMenuIten[];
  isOpened?: boolean;
}

type Props = {
  menu: IMenuIten[];
  deactivateParent?: () => void;
  menuLevel?: number;
};

const GenericDropdownMenu = ({ menu, deactivateParent, menuLevel = 1 }: Props) => {
  const router = useHistory();
  const [menuItems, setMenuItems] = useState<IMenuIten[]>(menu);
  const [activeItemId, setActiveItemId] = useState<number>(0);
  const PIXEL_PADDING_BY_LEVEL = 15;

  const deactivateItem = () => {
    setActiveItemId(0);
  };
  useEffect(() => {
    const currentPageHref = getFromStorage(CURRENT_PAGE_KEY);
    if (currentPageHref) {
      const foundItem = menuItems.find((item) => item.href === currentPageHref);
      if (foundItem) {
        setActiveItemId(foundItem.id);
        foundItem.href && router.push(foundItem.href);
      }
    }
  }, []);

  const toggleDropdown = (item: IMenuIten) => {
    if (item.children && item.children.length > 0) {
      menu.forEach((i) => {
        if (i.id !== item.id && i.children) {
          i.isOpened = false;
        }
      });
      item.isOpened = !item.isOpened;
    } else {
      if (deactivateParent) {
        deactivateParent();
      }
      menu.forEach((i) => {
        if (i.id !== item.id && i.children) {
          i.isOpened = false;
        }
      });
      setActiveItemId(item.id);
      addToStorage(CURRENT_PAGE_KEY, item.href || '');
      item.href && router.replace(`${item.href}`);
    }
    setMenuItems([...menu]);
  };

  return (
    <div
      style={{
        width: '280px',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
      }}>
      <ul
        style={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          borderRadius: ' border-radius: 3px',
          width: '100%',
          height: '100%',
          padding: 0,
          paddingLeft: 5,
          margin: 0
        }}>
        {menuItems.map((item, i) => (
          <li
            key={i}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              paddingTop: 16
            }}>
            <span
              onClick={() => toggleDropdown(item)}
              className='hover-dropdown-menu'
              style={{
                display: 'flex',
                width: '100%',
                height: '25px',
                backgroundColor: item.id === activeItemId ? '#F2F2F2' : '',
                borderRadius: '3px',
                borderLeft: item.id === activeItemId ? '5px solid black' : '2px solid transparent'
              }}>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  ...(deactivateParent
                    ? { paddingLeft: `${menuLevel * PIXEL_PADDING_BY_LEVEL}px` }
                    : {})
                }}>
                {item.children && (
                  <div
                    style={{
                      width: 15,
                      height: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <GenericIcons
                      icon='arrow'
                      imageStyle={{
                        transform: item.isOpened ? 'rotate(90deg)' : 'none',
                        width: '100%',
                        height: '100%'
                      }}
                    />
                  </div>
                )}
                {item.icon && (
                  <GenericIcons
                    icon={item.icon}
                    imageStyle={{
                      padding: 0,
                      marginRight: 3
                    }}
                  />
                )}
                <p>{item.label}</p>
              </span>
            </span>
            {item.children && item.isOpened && (
              <GenericDropdownMenu
                menu={item.children}
                deactivateParent={deactivateItem}
                menuLevel={menuLevel + 1}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GenericDropdownMenu;
