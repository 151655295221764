import React, { FunctionComponent, useState } from 'react';
import { Alert, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button, Icon } from 'semantic-ui-react';
import { WebEntity } from '../../entities/types';
import { BasicEntity } from '../../types';
import { capitalize } from '../../utils/utils';
import { GenericForm } from './GenericForm';
import GenericIcons from './GenericIcons';

const ModalButton: FunctionComponent<{ name: string; toggle: () => void }> = ({ name, toggle }) => (
  <button
    onClick={toggle}
    style={{
      display: 'flex',
      padding: '5px 15px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      backgroundColor: '#007DBA',
      borderRadius: '8px',
      border: 'none',
      color: '#fff',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px'
    }}>
    <GenericIcons
      icon='add'
      imageStyle={{
        margin: 0,
        padding: 0
      }}
    />
    {`Agregar ${capitalize(name)}`}
  </button>
);

const renderModalButton = (
  alternativeButton: ((toggle: () => void) => JSX.Element) | undefined,
  toggle: () => void,
  name: string
) => (alternativeButton ? alternativeButton(toggle) : <ModalButton toggle={toggle} name={name} />);

const voidFunction = () => {};

type GenericModalFormProps<T> = {
  webEntity: WebEntity<T>;
  errorMsg: string;
  isAuthenticated: boolean;
  isAdmin: boolean;
  actionFn: (formFields: any) => any;
  alternativeButton?: (toggle: () => void) => JSX.Element;
  entityToEdit?: BasicEntity;
  title?: string;
  centered?: boolean;
  closeOnClickOut?: boolean;
  actionName?: string;
};

const GenericModalForm = <T,>({
  webEntity,
  errorMsg = '',
  isAuthenticated,
  isAdmin,
  actionFn,
  alternativeButton,
  entityToEdit,
  title = '',
  centered = false,
  closeOnClickOut = false,
  actionName = ''
}: GenericModalFormProps<T>) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const onSubmit = async (e: Event, formFields: T) => {
    e.preventDefault();

    // Add Entity
    await actionFn(formFields);
    toggleModal();
  };

  const { name, fields, editableFields } = webEntity;

  if (entityToEdit && !editableFields) {
    throw new Error(`Entity [${webEntity.name}] does not provide a list of editable fields`);
  }

  return (
    <>
      {isAuthenticated ? (
        renderModalButton(alternativeButton, toggleModal, name)
      ) : (
        <h4 className='mb-3 ml4'>{`Identificate para administrar ${name}`}</h4>
      )}
      <Modal
        {...{
          isOpen: modal,
          autoFocus: false,
          centered,
          toggle: closeOnClickOut ? toggleModal : voidFunction
        }}>
        <ModalHeader toggle={toggleModal}>{title || `Agregar ${name}`}</ModalHeader>
        <ModalBody>
          {errorMsg && <Alert color='danger'>{errorMsg}</Alert>}
          <GenericForm
            {...{
              fields: entityToEdit ? editableFields : fields,
              onSubmit,
              actionName,
              isAdmin,
              entityToEdit
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default GenericModalForm;
