import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink } from 'reactstrap';
import { logout } from '../../actions/authActions';

interface IProps {
  logout: typeof logout;
}

const Logout: FunctionComponent<IProps> = ({ logout }) => {
  const history = useHistory();

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <NavLink
      onClick={handleLogout}
      href='#'
      style={{
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: ' 20px'
      }}>
      Cerrar sesión
    </NavLink>
  );
};

export default connect(null, { logout })(Logout);
