import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {};
const middleware = [thunk];

const __REDUX_DEVTOOLS_EXTENSION__ = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    ...(__REDUX_DEVTOOLS_EXTENSION__ ? [__REDUX_DEVTOOLS_EXTENSION__()] : [])
  )
);

// Global types for the state of the App
export type AppState = ReturnType<typeof store.getState>;

export type AppGetState = typeof store.getState;
export type AppDispatch = typeof store.dispatch;

export default store;
