import { ConteoCriticidadLog } from '../types';
import { dateFormatFn } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const ConteoCriticidadLogEntity: WebEntity<ConteoCriticidadLog> = {
  name: 'conteoCriticidadLog',
  endpoint: 'inventarioCriticidad/log',
  referenceColumn: 'id',

  tableColumns: [
    {
      name: 'ID',
      selector: 'id',
      omit: true
    },
    {
      name: 'ID Conteo Criticidad',
      selector: 'idConteoCriticidad',
      omit: true
    },
    {
      name: 'Comentario',
      selector: 'comentarioLog',
      wrap: true
    },
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      wrap: true,
      sortable: true
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      wrap: true,
      sortable: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      wrap: true,
      sortable: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      wrap: true,
      sortable: true
    },
    {
      name: 'Material',
      selector: 'material',
      wrap: true,
      sortable: true
    },
    {
      name: 'Lote',
      selector: 'lote',
      wrap: true,
      sortable: true
    },
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      format: ({ fechaRegistro: d }) => dateFormatFn(d, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Produccion ',
      selector: 'fechaProduccion',
      format: ({ fechaProduccion: d }) => dateFormatFn(d, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Vencimiento',
      selector: 'fechaVencimiento',
      format: ({ fechaVencimiento: d }) => dateFormatFn(d, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Unidad Cantidad Contada',
      selector: 'unidadCantidadContada',
      sortable: true
    },
    {
      name: 'Tipo Stock',
      selector: 'tipoStock',
      sortable: true
    },
    {
      name: 'Pedido Cliente',
      selector: 'pedidoCliente',
      sortable: true
    },
    {
      name: 'Numero Hu',
      selector: 'numeroHu',
      sortable: true
    },
    {
      name: 'ID Documento',
      selector: 'idDocumento',
      sortable: true
    },
    {
      name: 'Usuario',
      selector: 'idUsuario',
      sortable: true
    }
  ],

  fields: [
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Lote',
      selector: 'lote',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Unidad Cantidad Contada',
      selector: 'unidadCantidadContada',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Tipo Stock',
      selector: 'tipoStock',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Pedido Cliente',
      selector: 'pedidoCliente',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Numero Hu',
      selector: 'numeroHu',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      type: FieldTypes.Date,
      required: true
    },
    {
      name: 'Fecha Produccion',
      selector: 'fechaProduccion',
      type: FieldTypes.Date,
      required: true
    },
    {
      name: 'Fecha Vencimiento',
      selector: 'fechaVencimiento',
      type: FieldTypes.Date,
      required: true
    }
  ],

  editableFields: [],

  filterFields: [
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      required: true,
      type: FieldTypes.Date
    },
    {
      name: 'Usuario',
      selector: 'usuario',
      required: true,
      type: FieldTypes.Text
    }
  ]
};

export default ConteoCriticidadLogEntity;
