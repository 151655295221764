import { Inventario } from '../types';
import { dateFormatFn } from '../utils/utils';
import { getUncatchEndpointData } from '../utils/request';
import { FieldTypes, WebEntity, UpdateDependencyFunc } from './types';

const updateDependency =
  (
    fieldName: string,
    fieldToUpdate: 'fechaProduccion' | 'fechaVencimiento'
  ): UpdateDependencyFunc =>
    async (fieldValue, getField, setLoadings, _, updateFieldValue, getFieldValue): Promise<void> => {
      try {
        // we gonna modify fechaVencimiento given the fechaProduccion date
        const field = getField(fieldToUpdate);
        setLoadings([field], true);
        const material = getFieldValue('material');

        const { fecha: fechaStr } = await getUncatchEndpointData({
          endpoint: 'inventarioCriticidad/recalculate/fechaProdVenc',
          query: {
            fecha: fieldValue,
            material,
            isFechaProduccion: fieldName === 'fechaProduccion' ? true : false
          }
        });
        const fecha = new Date(fechaStr);
        updateFieldValue(fieldToUpdate, fecha);

        setLoadings([field], false);
      } catch (error) {
        console.log(error);
      }
    };

const InventarioCriticidadEntity: WebEntity<Inventario> = {
  name: 'inventarioCriticidad',
  endpoint: 'inventarioCriticidad',
  referenceColumn: 'material',

  tableColumns: [
    {
      name: 'ID',
      selector: 'id',
      omit: true
    },
    {
      name: 'ID Documento',
      selector: 'idDocumento',
      sortable: true
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      sortable: true,
      wrap: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      sortable: true
    },
    {
      name: 'Material',
      selector: 'material',
      sortable: true
    },
    {
      name: 'Descripción',
      selector: 'textoBreveMaterial',
      sortable: true,
      wrap: true,
      center: true
    },
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      sortable: true
    },
    {
      name: 'Fecha Produccion ',
      selector: 'fechaProduccion',
      format: ({ fechaProduccion }) => dateFormatFn(fechaProduccion, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Vencimiento',
      selector: 'fechaVencimiento',
      format: ({ fechaVencimiento }) => dateFormatFn(fechaVencimiento, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Unidad Cantidad Contada',
      selector: 'unidadCantidadContada',
      sortable: true
    },
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      format: ({ fechaRegistro }) => dateFormatFn(fechaRegistro, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Usuario',
      selector: 'nombreUsuario',
      sortable: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      sortable: true
    },
    {
      name: 'Lote',
      selector: 'lote',
      sortable: true,
      omit: true
    },
    {
      name: 'Tipo Stock',
      selector: 'tipoStock',
      sortable: true,
      omit: true
    },
    {
      name: 'Pedido Cliente',
      selector: 'pedidoCliente',
      sortable: true,
      omit: true
    },
    {
      name: 'Numero Hu',
      selector: 'numeroHu',
      sortable: true,
      omit: true
    }
  ],

  fields: [
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Lote',
      selector: 'lote',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Unidad Cantidad Contada',
      selector: 'unidadCantidadContada',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Tipo Stock',
      selector: 'tipoStock',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Pedido Cliente',
      selector: 'pedidoCliente',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Numero Hu',
      selector: 'numeroHu',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      type: FieldTypes.Date,
      required: true
    },
    {
      name: 'Fecha Produccion',
      selector: 'fechaProduccion',
      type: FieldTypes.Date,
      required: true
    },
    {
      name: 'Fecha Vencimiento',
      selector: 'fechaVencimiento',
      type: FieldTypes.Date,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      type: FieldTypes.Text
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Fecha Produccion',
      selector: 'fechaProduccion',
      type: FieldTypes.Date,
      updateDependency: updateDependency('fechaProduccion', 'fechaVencimiento')
    },
    {
      name: 'Fecha Vencimiento',
      selector: 'fechaVencimiento',
      type: FieldTypes.Date,
      updateDependency: updateDependency('fechaVencimiento', 'fechaProduccion')
    }
  ],

  filterFields: [
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      required: true,
      type: FieldTypes.Date
    },
    {
      name: 'Usuario',
      selector: 'usuario',
      required: true,
      type: FieldTypes.Text
    }
  ]
};

export default InventarioCriticidadEntity;
