import React, { FunctionComponent } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import GenericIcons from './GenericIcons';

type Props = {
  title?: string;
  buttonName?: string;
  buttonIcon?: SemanticICONS;
  openHook?: () => void;
  closeHook?: () => void;
  autoOpen?: number; // NOTE: A random number, such as Date.now()
  localIcon?: boolean;
  style?: {};
  modelstyle?: {};
  icon?: string;
};

const GenericModal: FunctionComponent<Props> = ({
  title = '',
  buttonName = '',
  buttonIcon,
  openHook = () => {},
  closeHook = () => {},
  autoOpen,
  children,
  localIcon,
  style,
  modelstyle,
  icon
}) => {
  const [open, setOpen] = React.useState(false);
  const triggerButton = React.useMemo(
    () => (
      <Button
        style={style}
        color='green'
        onClick={() => {
          setOpen(true);
          openHook();
        }}>
        {localIcon ? (
          <GenericIcons icon={icon || ''} />
        ) : icon ? (
          buttonIcon && <Icon name={buttonIcon} fitted />
        ) : null}
        {buttonName}
      </Button>
    ),
    [buttonIcon, buttonName, style, icon, openHook]
  );

  React.useEffect(() => {
    if (autoOpen) {
      setOpen(true);
    }
  }, [autoOpen]);

  const onClose = React.useMemo(
    () => () => {
      setOpen(false);
      closeHook();
    },
    [closeHook]
  );

  const Styles = {
    default: {
      height: 'auto',
      top: 'auto',
      left: 'auto',
      overflow: 'visible'
    }
  };
  return (
    <Modal
      //dimmer={'blurring'}
      open={open}
      size={'small'}
      style={modelstyle ? modelstyle : Styles.default}
      onClose={onClose}
      trigger={autoOpen ? undefined : triggerButton}>
      {title ? <Modal.Header>{title}</Modal.Header> : null}
      <Modal.Content>
        <Modal.Description>
          {typeof children === 'function' ? children(onClose) : children}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default GenericModal;
