import { Documento } from '../types';
import { FieldTypes, WebEntity, UpdateDependencyFunc } from './types';
import { getUncatchEndpointData } from '../utils/request';
import { dateFormatFn } from '../utils/utils';
import { TipoActivoValues } from '../types';

const updateDependencyFecha =
  (
    fieldName: string,
    fieldToUpdate:
      | 'fechaDocumento'
      | 'fechaDocumentoFin'
      | 'fechaCargaDocumento'
      | 'fechaCargaDocumentoFin'
  ): UpdateDependencyFunc =>
  async (fieldValue, getField, setLoadings, _, updateFieldValue, getFieldValue): Promise<void> => {
    try {
      const field = getField(fieldToUpdate);
      setLoadings([field], true);

      const fieldValueToUpdate = getFieldValue(fieldToUpdate);

      const fieldValueDateFormat = new Date(fieldValue);
      const fieldValueToUpdateDateFormat = new Date(fieldValueToUpdate);

      const checkIfIsStart = () =>
        fieldName === 'fechaDocumento' || fieldName === 'fechaCargaDocumento';
      const checkIfIsEnd = () =>
        fieldName === 'fechaDocumentoFin' || fieldName === 'fechaCargaDocumentoFin';
      const checkIfIsMajor = () => fieldValueDateFormat > fieldValueToUpdateDateFormat;
      const checkIfIsMinus = () => fieldValueDateFormat < fieldValueToUpdateDateFormat;

      let newValueToUpdate = fieldValueToUpdate;

      if (fieldValue && fieldValueToUpdate) {
        if ((checkIfIsEnd() && checkIfIsMinus()) || (!checkIfIsEnd() && checkIfIsMajor())) {
          newValueToUpdate = fieldValue;
        }
      } else if (
        (!fieldValue && fieldValueToUpdate && checkIfIsStart()) ||
        (fieldValue && !fieldValueToUpdate && checkIfIsEnd())
      ) {
        newValueToUpdate = fieldValue;
      }

      updateFieldValue(fieldToUpdate, newValueToUpdate);
      setLoadings([field], false);
    } catch (error) {
      console.log(error);
    }
  };

const updateDependency =
  (fieldName: string, fieldToUpdate: 'bodega'): UpdateDependencyFunc =>
  async (fieldValue, getField, setLoadings, _, updateFieldValue, getFieldValue): Promise<void> => {
    try {
      const field = getField(fieldToUpdate);
      setLoadings([field], true);
      const nombreCentro = getFieldValue(fieldName);

      if (nombreCentro) {
        const zona = await getUncatchEndpointData({
          endpoint: 'centroInventario/zonaDeTransporte',
          query: {
            nombreCentro: nombreCentro
          }
        });

        await updateFieldValue(fieldToUpdate, zona.zonaDeTransporte);
      }

      setLoadings([field], false);
    } catch (error) {
      console.log(error);
    }
  };

const updateDependencyMaterial =
  (fieldName: string, fieldToUpdate: 'textoBreveMaterial'): UpdateDependencyFunc =>
  async (
    fieldValue,
    getField,
    setLoadings,
    _,
    updateFieldValue,
    getFieldValue,
    getRow
  ): Promise<void> => {
    try {
      const field = getField(fieldToUpdate);
      setLoadings([field], true);
      const material = getFieldValue(fieldName + `_${getRow()}`).trim();

      const res = await getUncatchEndpointData({
        endpoint: 'sku/getTextoBreveMaterial',
        query: {
          material: material
        }
      });

      await updateFieldValue(fieldToUpdate, res.textoBreveMaterial, true);

      setLoadings([field], false);
    } catch (error) {
      console.log(error);
    }
  };

const DocumentoPalletEntity: WebEntity<Documento> = {
  name: 'documentoPallet',
  endpoint: 'documentosPallet',
  referenceColumn: 'idDocumento',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Documento ID',
      selector: 'idDocumento',
      sortable: true
    },
    {
      name: 'Usuario',
      selector: 'nombreUsuario'
    },
    {
      name: 'Estado Documento',
      selector: 'descripcionEstadoDocumento',
      conditionalCellStyles: [
        {
          when: (row) => row.idEstadoDocumento === 3,
          style: {
            color: 'var(--ccu-verde-claro, #64A70B)',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
          }
        },
        {
          when: (row) => row.idEstadoDocumento === 1,
          style: {
            color: 'var(--ccu-amarillo, #FFB81C)',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
          }
        },
        {
          when: (row) => row.idEstadoDocumento === 4,
          style: {
            color: 'var(--ccu-amarillo, red)',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
          }
        }
      ]
    },
    {
      name: 'Fecha Recuento',
      selector: 'fechaDocumento',
      format: ({ fechaDocumento: d }) => dateFormatFn(d, 'DD/MM/YYYY', true),
      sortable: true
    },
    {
      name: 'Fecha Carga',
      selector: 'fechaCargaDocumento',
      format: ({ fechaCargaDocumento: d }) => dateFormatFn(d),
      sortable: true
    },
    {
      name: 'Zona',
      selector: 'nombreCentro'
    }
  ],

  fields: [
    {
      name: 'Fecha de Recuento',
      selector: 'fechaDocumento',
      required: true,
      multiInput: {
        multiField: false,
        isDuplicable: false
      },
      type: FieldTypes.Date
    },
    {
      name: 'Zona',
      selector: 'zonaDeTransporte',
      type: FieldTypes.Reference,
      required: true,
      updateDependency: updateDependency('zonaDeTransporte', 'bodega'),
      multiInput: {
        multiField: false,
        isDuplicable: true
      },
      reference: {
        name: 'zone',
        endpoint: 'centroInventario/zonas',
        select: 'nombreCentro',
        show: 'nombreCentro'
      }
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      type: FieldTypes.Text,
      required: true,
      hidden: true,
      multiInput: {
        multiField: true,
        isDuplicable: true,
        setDefaultValueOnNewRow: {
          previous: true
        }
      }
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      type: FieldTypes.Text,
      columnClassName: 'col-sm-1',
      required: true,
      multiInput: {
        multiField: true,
        isDuplicable: true
      }
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      type: FieldTypes.Text,
      required: true,
      hidden: true,
      defaultValue: 'EN01',
      multiInput: {
        multiField: true,
        isDuplicable: true,
        setDefaultValueOnNewRow: {
          previous: true
        }
      }
    },
    {
      name: 'Material',
      selector: 'material',
      textUpperCase: true,
      type: FieldTypes.Text,
      columnClassName: 'col-sm-2',
      required: true,
      updateDependency: updateDependencyMaterial('material', 'textoBreveMaterial'),
      timerUpdateDependency: true,
      multiInput: {
        multiField: true,
        isDuplicable: true
      }
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      type: FieldTypes.Text,
      columnClassName: 'col',
      disabled: true,
      multiInput: {
        multiField: true,
        isDuplicable: true
      }
    },
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      type: FieldTypes.Date,
      required: true,
      hidden: true,
      defaultValue: new Date(),
      multiInput: {
        multiField: true,
        isDuplicable: false,
        setDefaultValueOnNewRow: {
          previous: true
        }
      }
    },
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      type: FieldTypes.Text,
      columnClassName: 'col-sm-1',
      required: true,
      multiInput: {
        multiField: true,
        isDuplicable: false
      }
    },
    {
      name: 'Utilizado',
      selector: 'tipoStock',
      type: FieldTypes.Reference,
      columnClassName: 'col-sm-4',
      required: true,
      multiInput: {
        multiField: true,
        isDuplicable: true
      },
      reference: {
        name: 'descripcionNomenclatura',
        endpoint: `nomenclaturas/${TipoActivoValues.pallet}`,
        select: 'descripcionNomenclatura',
        show: 'descripcionNomenclatura'
      }
    }
  ],

  editableFields: [
    {
      name: 'Estado',
      selector: 'idEstadoDocumento',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'estadoDocumento',
        endpoint: 'estadoDocumento',
        select: '_id',
        show: 'descripcionEstadoDocumento',
        filter: {
          activo: true
        }
      }
    }
  ],

  filterFields: [
    {
      name: 'Usuario',
      selector: 'idUsuario',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'usuario',
        endpoint: 'usuarios',
        select: '_id',
        show: 'nombreUsuario',
        filter: {
          activo: true
        }
      },
      onlyAdmin: true
    },
    {
      name: 'Estado Documento',
      selector: 'idEstadoDocumento',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'estadoDocumento',
        endpoint: 'estadoDocumento',
        select: '_id',
        show: 'descripcionEstadoDocumento',
        filter: {}
      }
    },
    {
      name: 'Fecha Recuento',
      selector: 'fechaDocumento',
      type: FieldTypes.Date,
      groupNumber: 1,
      required: false,
      updateDependency: updateDependencyFecha('fechaDocumento', 'fechaDocumentoFin')
    },
    {
      name: '-',
      selector: 'fechaDocumentoFin',
      type: FieldTypes.Date,
      groupNumber: 1,
      required: false,
      updateDependency: updateDependencyFecha('fechaDocumentoFin', 'fechaDocumento')
    },
    {
      name: 'Fecha Carga',
      selector: 'fechaCargaDocumento',
      type: FieldTypes.Date,
      groupNumber: 2,
      required: false,
      updateDependency: updateDependencyFecha('fechaCargaDocumento', 'fechaCargaDocumentoFin')
    },
    {
      name: '-',
      selector: 'fechaCargaDocumentoFin',
      type: FieldTypes.Date,
      groupNumber: 2,
      required: false,
      updateDependency: updateDependencyFecha('fechaCargaDocumentoFin', 'fechaCargaDocumento')
    }
  ]
};

export default DocumentoPalletEntity;
