import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';

const WelcomePage: FunctionComponent<{}> = () => (
  <div style={{ marginInline: '2em' }}>
    <h1> Welcome to This Page</h1>
  </div>
);

export default connect(({ auth }: AppState) => ({ auth }))(WelcomePage);
