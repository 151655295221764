import { BasicEntity } from '../types';
import {
  ADD_ENTITY,
  DELETE_ENTITY,
  EDIT_ENTITY,
  EntitiesActions,
  EntitiesState,
  GET_ENTITY,
  LOADING_ENTITY,
  SELECTED_ENTITY,
  SINGLE_SELECTED_ENTITY,
  NOTIFY_MESSAGE_ENTITY,
  REFRESH_ENTITY
} from './types';

const DefaultEntityState = {
  list: [],
  selected: [],
  singleSelected: null,
  loading: false,
  notificationMessage: ''
};

const initialState: EntitiesState = {
  usuario: DefaultEntityState,
  tipoUsuario: DefaultEntityState,
  estadoDocumento: DefaultEntityState,
  sku: DefaultEntityState,
  skuFactor: DefaultEntityState,
  perfilEnvejecimiento: DefaultEntityState,
  diasVenta: DefaultEntityState,
  centroInventario: DefaultEntityState,
  zonasOperador: DefaultEntityState,
  activo: DefaultEntityState,
  tipoActivo: DefaultEntityState,
  nomenclatura: DefaultEntityState,
  relacionEnvaseCasillero: DefaultEntityState,
  reporteZonaCategoriaSKU: DefaultEntityState,
  reporteVencimiento: DefaultEntityState,
  reporteInventarioCriticidad: DefaultEntityState,
  documentoCriticidad: DefaultEntityState,
  documentoEnvases: DefaultEntityState,
  documentoPallet: DefaultEntityState,
  inventarioCriticidad: DefaultEntityState,
  inventarioEnvases: DefaultEntityState,
  inventarioPallet: DefaultEntityState,
  conteoCriticidadLog: DefaultEntityState,
  inconsistenciaFechaLog: DefaultEntityState
};

const entitiesReducer = (state = initialState, action: EntitiesActions): EntitiesState => {
  switch (action.type) {
    case GET_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], list: action.payload }
      };

    case SELECTED_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], selected: action.payload }
      };

    case SINGLE_SELECTED_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], singleSelected: action.payload }
      };

    case DELETE_ENTITY:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          list: (state[action.entityName].list as BasicEntity[]).filter(
            (entity) => !action.payload.includes(entity._id)
          )
        }
      };

    case EDIT_ENTITY:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          list: (state[action.entityName].list as BasicEntity[]).map((e) =>
            e._id === action.payload._id ? action.payload : e
          )
        }
      };

    case ADD_ENTITY:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          list: [...action.payload, ...state[action.entityName].list]
        }
      };

    case LOADING_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], loading: action.payload }
      };

    case NOTIFY_MESSAGE_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], notificationMessage: action.payload }
      };

    case REFRESH_ENTITY:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          list: [...state[action.entityName].list]
        }
      };

    default:
      return state;
  }
};

export default entitiesReducer;
