export type SimpleObject = { [key: string]: any };
export type SimpleStringObject = { [key: string]: string };
export type CustomObject<T> = { [key: string]: T };

export enum ImageFormats {
  Png = 'png',
  Jpg = 'jpeg'
}

export enum TipoUsuarioValues {
  Operador = 'OPERADOR',
  Admin = 'ADMIN',
  Visualizador = 'VISUALIZADOR'
}

export enum TipoActivoValues {
  productoTerminado = '1',
  envaseCasillero = '2',
  pallet = '3'
}

export enum NameConteoValues {
  conteoCriticidad = 'Conteo criticidad',
  conteoEnvases = 'Conteo envases',
  conteoPallet = 'Conteo pallet'
}

export enum QueryNameValues {
  reporteCategoriaConteoMonto = 'reporteCategoriaConteoMonto',
  reporteCategoriaConteoCantidad = 'reporteCategoriaConteoCantidad',
  reporteZonaConetoMonto = 'reporteZonaConetoMonto',
  reporteZonaConetoCantidad = 'reporteZonaConetoCantidad',
  reporteSKUMonto = 'reporteSKUMonto',
  reporteSKUCantidad = 'reporteSKUCantidad',
  reporteVencimientoConteoCantidad = 'reporteVencimientoConteoCantidad',
  reporteVencimientoConteoMonto = 'reporteVencimientoConteoMonto',
  reporteInventarioCriticidad = 'reporteInventarioCriticidad',
  reporteInconsistenciasFecha = 'reporteInconsistenciasFecha'
}

export type QueryNameType = (typeof QueryNameValues)[keyof typeof QueryNameValues];

export interface TipoUsuario extends BasicEntity {
  nombre: TipoUsuarioValues;
  activo: boolean;
}

export interface User extends BasicEntity {
  activo: boolean;
  nombre: string;
  nombreUsuario: string;
  emailUsuario: string;
  nombreTipoUsuario: string;
  idTipoUsuario: string;
  claveUsuario: string;
}

export interface ZonasOperador extends BasicEntity {
  idUsuario: string;
  nombreUsuario: string;
  zonasAsignadas: Array<String>;
}

export interface Activo extends BasicEntity {
  idActivo: string;
  tipoMaterial: string;
  grupoArticulos: string;
  idTipoActivo: string;
}

export interface TipoActivo extends BasicEntity {
  idTipoActivo: string;
  descripcion: string;
}

export interface Nomenclatura extends BasicEntity {
  idNomenclatura: string;
  idTipoActivo: string;
  estado: string;
  agregarCasillero: boolean;
}

export interface RelacionEnvaseCasillero extends BasicEntity {
  idRelacion: string;
  materialContado: string;
  descripcionMaterialContado: string;
  cantidadContado: string;
  materialRelacion: string;
  descripcionMaterialRelacion: string;
  cantidadRelacion: string;
}

export interface Inventario extends BasicEntity {
  cantidadContada: string;
  bodega: string;
  centro: string;
  almacen: string;
  material: string;
  lote: string;
  fechaRegistro: string;
  fechaProduccion: string;
  fechaVencimiento: string;
  unidadCantidadContada: string;
  tipoStock: string;
  pedidoCliente: string;
  numeroHu: string;
  idDocumento: string;
  usuario: string;
  descripcion: string;
}

export interface ConteoCriticidadLog extends Inventario {
  idConteoCriticidad: string;
}

export interface InconsistenciaFechaLog extends BasicEntity {
  idConteo_criticidad: string;
  cantidadContada: number;
  idDocumento: string;
  material: string;
  idCentro: string;
  fechaDocumento: string;
  fechaProduccion: string;
  fechaVencimiento: string;
  duracionTotalConservacion: number;
  perfilEnvejecimiento: number;
  descripcionEstadoDocumento: string;
}

export interface Documento extends BasicEntity {
  _id: string;
  fechaDocumento: string;
  idEstadoDocumento: string;
  fechaCargaDocumento: string;
  nombreUsuario?: string;
}

export interface CentroInventario extends BasicEntity {
  _id: string;
  idEmpresa: string;
  empresa: string;
  zona: string;
  nombreCentro: string;
  zonaDeTransporte: string;
  centroSieteHoras: string;
  bodegaExterna: boolean;
}

export interface EstadoDocumento extends BasicEntity {
  descripcionEstadoDocumento: string;
}

export interface ReporteZonaCategoriaSKU extends BasicEntity {
  orden: number;
  tipoProducto?: string;
  grupoProducto?: string;
  numeroAntiguoMaterial?: string;
  textoBreveMaterial?: string;
  zona?: string;
  menor_21Dias: string;
  menor_41Dias: string;
  menor_61Dias: string;
  menor_121Dias: string;
  mayor_121Dias: string;
  totalGeneral: string;
}

export interface ReporteVencimiento extends BasicEntity {
  orden: number;
  numeroAntiguoMaterial: string;
  textoBreveMaterial: string;
  m01: string;
  m02: string;
  m03: string;
  m04: string;
  totalGeneral: string;
}

export interface ReporteInventarioCriticidad extends BasicEntity {
  zona: string;
  nombreCentro: string;
  cpch: string;
  vspt: string;
  ccuChile: string;
  ecomccu: string;
  eccusa: string;
  aguasCcuNestle: string;
}

export interface DiasVenta extends BasicEntity {
  depDist: string;
  grupoProducto: string;
  producto: string;
  marca: string;
  promoVentaMes: string;
  forecast: string;
  porcentajeForecast: string;
}

export interface Sku extends BasicEntity {
  material: string;
  centro: string;
  claseValoracion: string;
  textoBreve_material: string;
  ultimaModificacion: string;
  tipoMaterial: string;
  grupoArticulos: string;
  unidadMedida_base: string;
  grupoCompra: string;
  indicadorAbc: string;
  caracteristicaPlanificacionNec: string;
  categoriaValoracion: string;
  controlPrecio: string;
  precio: string;
  moneda: string;
  cantidadBase: string;
  creadoPor: string;
}

export interface PerfilEnvejecimiento extends BasicEntity {
  material: string;
  numeroAntiguoMaterial: string;
  descripcion: string;
  vence: boolean;
  unidadMedidaBase: string;
  grupoArticulos: string;
  duracionTotalConservacion: string;
}

export interface SkuFactor extends BasicEntity {
  material: string;
  factorTccu: string;
  grupoArticulo: string;
  descripcion: string;
}

export interface BasicEntity {
  _id: string;
}
