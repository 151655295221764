import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../img/ccu_logo.png';
import React, { FunctionComponent, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { loadUser } from '../actions/authActions';
import '../css/AppHome.css';
import Pages, { Mantenedores, Reportes } from '../pages';
import AppNavbar from './AppNavbar';
import { AppLink } from './types';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import Login from './auth/Login';
import GenericContainerSidebar from './generics/GenericContainerSidebar/GenericContainerSidebar';
import Logout from './auth/Logout';
import {
  SkuMaintainer,
  DiasVentaMaintainer,
  PerfilEnvejecimientoMaintainer,
  ZonasOperadoresCRUDMaintainer
} from '../pages/Mantenedores';

export const hiddenLinks: AppLink[] = [
  {
    href: '/landing',
    name: 'Landing',
    component: Pages.LandingPage
  },
  {
    href: '/',
    name: 'Inicio',
    component: Pages.AnonimousPage
  }
];

export const publicLinks: AppLink[] = [];

const adminLinks: AppLink[] = [
  {
    href: '/mantenedores/Usuario',
    name: 'Usuario',
    component: Mantenedores.UsuarioCRUDMaintainer
  },
  {
    href: '/mantenedores/TipoUsuarios',
    name: 'Tipo Uusario',
    component: Mantenedores.TipoUsuarioCRUDMaintainer
  },
  {
    href: '/mantenedores/EstadoDocumentos',
    name: 'Estado Documento',
    component: Mantenedores.EstadoDocumentoCRUDMaintainer
  },
  {
    href: '/mantenedores/SKU',
    name: 'SKU',
    component: SkuMaintainer
  },
  {
    href: '/mantenedores/SKUFactor',
    name: 'SKU Factor',
    component: Mantenedores.SkuFactorCRUDMaintainer
  },
  {
    href: '/mantenedores/PerfilEnvejecimiento',
    name: 'Perfil Envejecimiento',
    component: PerfilEnvejecimientoMaintainer
  },
  {
    href: '/mantenedores/DiasVentas',
    name: 'Dias Ventas',
    component: DiasVentaMaintainer
  },
  {
    href: '/mantenedores/Centros',
    name: 'Centro Inventario',
    component: Mantenedores.CentroInventarioCRUDMaintainer
  },
  {
    href: '/mantenedores/BodegasUsuarios',
    name: 'Bodega Usuarios',
    component: ZonasOperadoresCRUDMaintainer
  },
  {
    href: '/mantenedores/Activos',
    name: 'Activos',
    component: Mantenedores.ActivosCRUDMaintainer
  },
  {
    href: '/mantenedores/TipoActivos',
    name: 'Tipo de Activos',
    component: Mantenedores.TipoActivosCRUDMaintainer
  },
  {
    href: '/mantenedores/Nomenclaturas',
    name: 'Nomenclaturas',
    component: Mantenedores.NomenclaturasCRUDMaintainer
  },
  {
    href: '/mantenedores/RelacionEnvasesCasillero',
    name: 'Relacion Envases Casillero',
    component: Mantenedores.RelacionEnvaseCasilleroCRUDMaintainer
  }
];

export const operadorLinks: AppLink[] = [
  {
    href: '/conteo-criticidad',
    name: 'Conteo Criticidad',
    component: Pages.ConteoCriticidadPage
  },
  {
    href: '/conteo-envases',
    name: 'Conteo Envases',
    component: Pages.ConteoEnvasesPage
  },
  {
    href: '/conteo-pallet',
    name: 'Conteo Pallet',
    component: Pages.ConteoPalletPage
  },
  /* Reportes Incosistencias */
  {
    href: '/reportes/incosistencias/conteos-criticidad',
    name: 'Reportes',
    component: Reportes.conteosCriticidad.ReporteConteoCriticidadLogComponent
  },
  {
    href: '/reportes/incosistencias/fecha',
    name: 'Reportes',
    component: Reportes.conteosCriticidad.ReporteInconsistenciaFechaLogComponent
  },
  /* Reportes Incosistencias */
  /*Reportes Generales */
  {
    href: '/reportes/generales/categoria-monto',
    name: 'Reportes',
    component: Reportes.generales.ReporteCategoriaConteoMontoComponent
  },
  {
    href: '/reportes/generales/categoria-conteo-cantidad',
    name: 'Reportes',
    component: Reportes.generales.ReporteCategoriaConteoCantidadComponent
  },
  {
    href: '/reportes/generales/zona-conteo-monto',
    name: 'Reportes',
    component: Reportes.generales.ReporteZonaConteoMontoComponent
  },
  {
    href: '/reportes/generales/zona-conteo-cantidad',
    name: 'Reportes',
    component: Reportes.generales.ReporteZonaConteoCantidadComponent
  },
  {
    href: '/reportes/generales/sku-monto',
    name: 'Reportes',
    component: Reportes.generales.ReporteSKUMontoComponent
  },
  {
    href: '/reportes/generales/sku-cantidad',
    name: 'Reportes',
    component: Reportes.generales.ReporteSKUCantidadComponent
  },
  {
    href: '/reportes/generales/vencimiento-conteo-monto',
    name: 'Reportes',
    component: Reportes.generales.ReporteVencimientoConteoMontoComponent
  },
  {
    href: '/reportes/generales/vencimiento-conteo-cantidad',
    name: 'Reportes',
    component: Reportes.generales.ReporteVencimientoConteoCantidadComponent
  },
  {
    href: '/reportes/generales/inventario-criticidad',
    name: 'Reportes',
    component: Reportes.generales.ReporteInventarioCriticidadComponent
  }

  /*Reportes Generales */
];

const autoRoutes = () =>
  [...hiddenLinks, ...publicLinks, ...adminLinks, ...operadorLinks].map(
    ({ component, href }, k) => (
      <Route key={`main_menu_${k}`} exact path={href} component={component} />
    )
  );

type Props = {
  loadUser: () => Promise<void>;
  auth: AuthState;
};

const App: FunctionComponent<Props> = ({ auth, loadUser }) => {
  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <div className='App'>
      <Helmet>
        <meta name={'X-App-Version'} content={`${process.env.REACT_APP_VERSION}`} />
      </Helmet>
      <Router>
        {auth.isAuthenticated ? (
          <GenericContainerSidebar
            img={logo}
            AppNavbar={<AppNavbar />}
            linkTopNav={'/conteo-criticidad'}
            logout={<Logout />}
            user={auth.user?.nombreUsuario}>
            <Switch>
              {autoRoutes()}
              <Route path='/404' component={Pages.NotFoundPage} />
              <Redirect to='/404' />
            </Switch>
          </GenericContainerSidebar>
        ) : (
          <Login />
        )}
      </Router>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }), { loadUser })(App);
