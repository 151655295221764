import React, { FunctionComponent } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

export type PaginationProps = {
  rowsPerPage: number;
  rowCount: number;
  currentPage: number;
  availablePages: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: () => void;
};

const PaginationWrapper = styled.nav`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  ${({ theme }: { theme: any }) => theme.pagination.style};
`;

const Pagination: FunctionComponent<PaginationProps> = ({
  rowCount,
  currentPage,
  availablePages,
  onChangePage
}): JSX.Element => (
  <PaginationWrapper className='rdt_Pagination'>
    <div style={{ margin: '0 10px 0 10px' }}>Total de elementos: {rowCount}</div>
    <Button
      type='button'
      onClick={() => onChangePage(currentPage - 1)}
      disabled={currentPage === 1}>
      <Icon name={'step backward'} />
    </Button>
    <div style={{ margin: '0 10px 0 10px' }}>
      P&aacute;gina {currentPage} de {availablePages}
    </div>
    <Button
      type='button'
      onClick={() => onChangePage(currentPage + 1)}
      disabled={currentPage === availablePages}>
      <Icon name={'step forward'} />
    </Button>
  </PaginationWrapper>
);

export default Pagination;
