import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';

const ObserverPage: FunctionComponent<{}> = () => (
  <div style={{ marginInline: '2em' }}>
    <h1> WelCome to this page </h1>
  </div>
);

export default connect(({ auth }: AppState) => ({ auth }))(ObserverPage);
