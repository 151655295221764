import axios from 'axios';
import * as E from '../entities';
import { WebEntityName } from '../entities/types';
import { AppDispatch, AppGetState } from '../store';
import { tokenConfig } from '../utils/header';
import { getEntitiesAction, voidEntitiesAction, setEntityLoading } from './entitiesActions';
import { DiasVenta, Inventario, PerfilEnvejecimiento, Sku } from '../types';
import { handleError } from '../components/PopAlert';
import { cleanTextDate } from '../utils/utils';
import { REFRESH_ENTITY } from '../reducers/types';

// REFRESHERS
const refreshInventarioCriticidad =
  (idDocumento: string) => async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(
      E.InventarioCriticidadEntity.name as WebEntityName,
      `${E.InventarioCriticidadEntity.endpoint}/${idDocumento}`,
      {}
    )()(dispatch, getState);

const refreshInventarioEnvases =
  (idDocumento: string) => async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(
      E.InventarioEnvasesEntity.name as WebEntityName,
      `${E.InventarioEnvasesEntity.endpoint}/${idDocumento}`,
      {}
    )()(dispatch, getState);

const refreshInventarioPallet =
  (idDocumento: string) => async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(
      E.InventarioPalletEntity.name as WebEntityName,
      `${E.InventarioPalletEntity.endpoint}/${idDocumento}`,
      {}
    )()(dispatch, getState);

const refreshDocumentoCriticidad = async (dispatch: AppDispatch, getState: AppGetState) =>
  await getEntitiesAction(
    E.DocumentoCriticidadEntity.name as WebEntityName,
    E.DocumentoCriticidadEntity.endpoint,
    {}
  )()(dispatch, getState);

const refreshDocumentoEnvases = async (dispatch: AppDispatch, getState: AppGetState) =>
  await getEntitiesAction(
    E.DocumentoEnvasesEntity.name as WebEntityName,
    E.DocumentoEnvasesEntity.endpoint,
    {}
  )()(dispatch, getState);

const refreshDocumentoPallet = async (dispatch: AppDispatch, getState: AppGetState) =>
  await getEntitiesAction(
    E.DocumentoPalletEntity.name as WebEntityName,
    E.DocumentoPalletEntity.endpoint,
    {}
  )()(dispatch, getState);

const refreshSku = async (dispatch: AppDispatch, getState: AppGetState) =>
  await getEntitiesAction(E.SkuEntity.name as WebEntityName, E.SkuEntity.endpoint, {})(1)(
    dispatch,
    getState
  );

const refreshDiasVenta = async (dispatch: AppDispatch, getState: AppGetState) =>
  await getEntitiesAction(
    E.DiasVentaEntity.name as WebEntityName,
    E.DiasVentaEntity.endpoint,
    {}
  )(1)(dispatch, getState);

const refreshPerfilEnvejecimiento = async (dispatch: AppDispatch, getState: AppGetState) =>
  await getEntitiesAction(
    E.PerfilEnvejecimientoEntity.name as WebEntityName,
    E.PerfilEnvejecimientoEntity.endpoint,
    {}
  )(1)(dispatch, getState);

const refresReporteConteoCriticidadLog = async (dispatch: AppDispatch, getState: AppGetState) =>
  await getEntitiesAction(
    E.ConteoCriticidadLogEntity.name as WebEntityName,
    E.ConteoCriticidadLogEntity.endpoint,
    {}
  )()(dispatch, getState);

const refresReporteInconsistenciaFechaLog = async (dispatch: AppDispatch, getState: AppGetState) =>
  await getEntitiesAction(
    E.InconsistenciaFechaLogEntity.name as WebEntityName,
    E.InconsistenciaFechaLogEntity.endpoint,
    {}
  )()(dispatch, getState);

/**
 * Add Inventario Criticidad
 *
 * @param inventarioCriticidad: Array<Inventario>
 */
export const ingresarInventarioCriticidad =
  (inventarioCriticidad: Array<Inventario>, date: Date, zona: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/inventarioCriticidad`,
        { data: inventarioCriticidad, fechaDocumento: cleanTextDate(date), zona },
        tokenConfig(getState)
      );
      await refreshDocumentoCriticidad(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.InventarioCriticidadEntity.name, dispatch });
    }
  };

/**
 * Add Inventario Envases
 *
 * @param inventarioEnvases: Array<Inventario>
 */
export const ingresarInventarioEnvases =
  (inventarioEnvases: Array<Inventario>, date: Date, zona: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/inventarioEnvases`,
        { data: inventarioEnvases, fechaDocumento: cleanTextDate(date), zona },
        tokenConfig(getState)
      );
      await refreshDocumentoEnvases(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.InventarioEnvasesEntity.name, dispatch });
    }
  };

/**
 * Add Inventario Pallet
 *
 * @param inventarioPallet: Array<Inventario>
 */
export const ingresarInventarioPallet =
  (inventarioPallet: Array<Inventario>, date: Date, zona: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/inventarioPallet`,
        { data: inventarioPallet, fechaDocumento: cleanTextDate(date), zona },
        tokenConfig(getState)
      );
      await refreshDocumentoPallet(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.InventarioPalletEntity.name, dispatch });
    }
  };

export const mergeSku =
  (skuData: Array<Sku>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/sku/merge`, skuData, tokenConfig(getState));
      await refreshSku(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.SkuEntity.name, dispatch });
    }
  };

export const diasVentaMassiveLoad =
  (diasVentaData: Array<DiasVenta>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/diasVenta/massiveLoad`, diasVentaData, tokenConfig(getState));
      await refreshDiasVenta(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.DiasVentaEntity.name, dispatch });
    }
  };

export const perfilEnvejecimientoMassiveLoad =
  (perfilEnvejecimientoData: Array<PerfilEnvejecimiento>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(
        `/api/perfilEnvejecimiento/massiveLoad`,
        perfilEnvejecimientoData,
        tokenConfig(getState)
      );
      await refreshPerfilEnvejecimiento(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.PerfilEnvejecimientoEntity.name, dispatch });
    }
  };

export const fetchDocumentInventarioCriticidad =
  (idDocumento: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading('inventarioCriticidad'));

    try {
      voidEntitiesAction(E.InventarioCriticidadEntity.name as WebEntityName)()(dispatch);
      await refreshInventarioCriticidad(idDocumento)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InventarioCriticidadEntity.name, dispatch });
    }
  };

export const refreshConteoCriticidadLog =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/inventarioCriticidad/log/refresh`, {}, tokenConfig(getState));
      await refresReporteConteoCriticidadLog(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.ConteoCriticidadLogEntity.name, dispatch });
    }
  };

export const refreshReporteInconsistenciaFechaLog =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(
        `/api/inventarioCriticidad/inconsistenciaFechaLog/refresh`,
        {},
        tokenConfig(getState)
      );
      await refresReporteInconsistenciaFechaLog(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.ConteoCriticidadLogEntity.name, dispatch });
    }
  };

export const fetchDocumentInventarioEnvases =
  (idDocumento: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading('inventarioEnvases'));

    try {
      voidEntitiesAction(E.InventarioEnvasesEntity.name as WebEntityName)()(dispatch);
      await refreshInventarioEnvases(idDocumento)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InventarioEnvasesEntity.name, dispatch });
    }
  };

export const fetchDocumentInventarioPallet =
  (idDocumento: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading('inventarioPallet'));

    try {
      voidEntitiesAction(E.InventarioPalletEntity.name as WebEntityName)()(dispatch);
      await refreshInventarioPallet(idDocumento)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InventarioPalletEntity.name, dispatch });
    }
  };

export const cleanSelectedEntities =
  (entityName: WebEntityName) => async (dispatch: AppDispatch) => {
    dispatch({
      type: REFRESH_ENTITY,
      payload: null,
      entityName: entityName
    });
  };
