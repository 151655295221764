import { Inventario, TipoActivoValues } from '../types';
import { dateFormatFn } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const InventarioEnvasesEntity: WebEntity<Inventario> = {
  name: 'inventarioEnvases',
  endpoint: 'inventarioEnvases',
  referenceColumn: 'material',

  tableColumns: [
    {
      name: 'ID',
      selector: 'id',
      omit: true
    },
    {
      name: 'ID Documento',
      selector: 'idDocumento',
      sortable: true
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      sortable: true,
      wrap: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      sortable: true
    },
    {
      name: 'Material',
      selector: 'material',
      sortable: true,
      wrap: true
    },
    {
      name: 'Descripción',
      selector: 'textoBreveMaterial',
      sortable: true,
      wrap: true,
      center: true
    },
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      sortable: true
    },
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      format: ({ fechaRegistro }) => dateFormatFn(fechaRegistro, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Utilizado',
      selector: 'tipoStock',
      sortable: true,
      wrap: true
    },
    {
      name: 'Usuario',
      selector: 'nombreUsuario',
      sortable: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      sortable: true
    },
    {
      name: 'Lote',
      selector: 'lote',
      sortable: true,
      omit: true
    },
    {
      name: 'Pedido Cliente',
      selector: 'pedidoCliente',
      sortable: true,
      omit: true
    },
    {
      name: 'Numero Hu',
      selector: 'numeroHu',
      sortable: true,
      omit: true
    }
  ],

  fields: [
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Lote',
      selector: 'lote',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Unidad Cantidad Contada',
      selector: 'unidadCantidadContada',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Tipo Stock',
      selector: 'tipoStock',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Pedido Cliente',
      selector: 'pedidoCliente',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Numero Hu',
      selector: 'numeroHu',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      type: FieldTypes.Date,
      required: true
    },
    {
      name: 'Fecha Produccion',
      selector: 'fechaProduccion',
      type: FieldTypes.Date,
      required: true
    },
    {
      name: 'Fecha Vencimiento',
      selector: 'fechaVencimiento',
      type: FieldTypes.Date,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      type: FieldTypes.Text
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Utilizado',
      selector: 'tipoStock',
      type: FieldTypes.Reference,
      reference: {
        name: 'descripcionNomenclatura',
        endpoint: `nomenclaturas/${TipoActivoValues.envaseCasillero}`,
        select: 'descripcionNomenclatura',
        show: 'descripcionNomenclatura'
      }
    }
  ],

  filterFields: [
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      required: true,
      type: FieldTypes.Date
    },
    {
      name: 'Usuario',
      selector: 'usuario',
      required: true,
      type: FieldTypes.Text
    }
  ]
};

export default InventarioEnvasesEntity;
