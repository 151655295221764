import { RelacionEnvaseCasillero } from '../types';
import { FieldTypes, WebEntity } from './types';

const RelacionEnvaseCasilleroEntity: WebEntity<RelacionEnvaseCasillero> = {
  name: 'relacionEnvaseCasillero',
  endpoint: 'relacionEnvasesCasilleros',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: 'idRelacion'
    },
    {
      name: 'Material Contado',
      selector: 'materialContado'
    },
    {
      name: 'Descripción',
      selector: 'descripcionMaterialContado',
      wrap: true
    },
    {
      name: 'Cantidad',
      selector: 'cantidadContado'
    },
    {
      name: 'Material Relacion',
      selector: 'materialRelacion'
    },
    {
      name: 'Descripcion Relacion',
      selector: 'descripcionMaterialRelacion',
      wrap: true
    },
    {
      name: 'Cantidad Relacion',
      selector: 'cantidadRelacion'
    }
  ],

  fields: [
    {
      name: 'Material Contado',
      selector: 'materialContado',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Cantidad',
      selector: 'cantidadContado',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Material Relacion',
      selector: 'materialRelacion',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Cantidad Relacion',
      selector: 'cantidadRelacion',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'ID Relacion',
      selector: 'idRelacion',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Material Contado',
      selector: 'materialContado',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Cantidad',
      selector: 'cantidadContado',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Material Relacion',
      selector: 'materialRelacion',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Cantidad Relacion',
      selector: 'cantidadRelacion',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default RelacionEnvaseCasilleroEntity;
