import React, { FunctionComponent } from 'react';
import * as Papa from 'papaparse';
import { Button, Icon } from 'semantic-ui-react';

const exportCSVHeader = (headers: Array<string>) => () => {
  try {
    const data = [['sep=,'], headers];
    const csv = Papa.unparse(data);
    const blob = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'plantilla.csv');
    link.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};

const GenericHeaderExportButton: FunctionComponent<{ headers: Array<string> }> = ({ headers }) => {
  return (
    <div>
      <Button color='yellow' onClick={exportCSVHeader(headers)} floated='right'>
        <Icon name='download' />
        Descargar plantilla CSV
      </Button>
    </div>
  );
};

export default GenericHeaderExportButton;
