import { InconsistenciaFechaLog } from '../types';
import { dateFormatFn } from '../utils/utils';
import { WebEntity, FieldTypes } from './types';
import { updateFechaDocumentoDependency } from './reports/sharedComponents';

/**
 id_conteo_criticidad
 cantidad_contada
 id_documento
 material
 id_centro
 fecha_documento
 fecha_produccion
 fecha_vencimiento
 duracion_total_conservacion
 perfil_envejecimiento
*/

const InconsistenciaFechaLogEntity: WebEntity<InconsistenciaFechaLog> = {
  name: 'inconsistenciaFechaLog',
  endpoint: 'inventarioCriticidad/inconsistenciaFechaLog',
  referenceColumn: 'id',

  tableColumns: [
    {
      name: 'ID',
      selector: 'id',
      omit: true
    },
    {
      name: 'ID Conteo Criticidad',
      selector: 'idConteoCriticidad',
      omit: true
    },
    {
      name: 'ID Documento',
      selector: 'idDocumento'
    },
    {
      name: 'Material',
      selector: 'material',
      wrap: true,
      sortable: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      wrap: true,
      sortable: true
    },
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      wrap: true,
      sortable: true
    },
    {
      name: 'Fecha Recuento',
      selector: 'fechaDocumento',
      format: ({ fechaDocumento: d }) => dateFormatFn(d, 'DD/MM/YYYY', true),
      sortable: true
    },
    {
      name: 'Fecha Produccion',
      selector: 'fechaProduccion',
      format: ({ fechaProduccion: d }) => dateFormatFn(d, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Vencimiento',
      selector: 'fechaVencimiento',
      format: ({ fechaVencimiento: d }) => dateFormatFn(d, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Error Vida Útil',
      selector: 'duracionTotalConservacion',
      sortable: true
    },
    {
      name: 'Perfil Envejecimiento',
      selector: 'perfilEnvejecimiento',
      sortable: true
    },
    {
      name: 'Estado Documento',
      selector: 'descripcionEstadoDocumento',
      wrap: true,
      sortable: true
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [
    {
      name: 'Fecha Recuento',
      selector: 'fechaDocumento',
      required: true,
      type: FieldTypes.Date,
      updateDependency: updateFechaDocumentoDependency
    },
    {
      name: 'Zona de Transporte',
      selector: 'zonaDeTransporte',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'zonaDeTransporte',
        endpoint: 'reportes/filtros/zonaDeTransporteInconsistenciaFecha',
        select: 'zonaDeTransporte',
        show: 'zonaDeTransporte'
      }
    }
  ]
};

export default InconsistenciaFechaLogEntity;
