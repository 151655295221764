import { UpdateDependencyFunc } from '../types';
import { getUncatchEndpointData } from '../../utils/request';

export const updateFechaInventarioDependency: UpdateDependencyFunc = async (
  fieldValue,
  getField,
  setLoadings,
  updateFetchedFields,
  _,
  __
): Promise<void> => {
  try {
    // Given fechaInventario we will refetch "numeroAntiguoMaterial" and "textoBreveMaterial"
    const numeroAntiguoMaterialField = getField('numeroAntiguoMaterial');
    const textoBreveMaterialField = getField('textoBreveMaterial');
    const material = getField('material');
    const zonaDeTransporte = getField('zonaDeTransporte');
    const zona = getField('zona');
    setLoadings([numeroAntiguoMaterialField, textoBreveMaterialField], true);

    const zonaData = await getUncatchEndpointData({
      endpoint: zona.reference.endpoint,
      query: { fechaInventario: fieldValue }
    });
    const numeroAntiguoMaterialData = await getUncatchEndpointData({
      endpoint: numeroAntiguoMaterialField.reference.endpoint,
      query: { fechaInventario: fieldValue }
    });

    const textoBreveMaterialData = await getUncatchEndpointData({
      endpoint: textoBreveMaterialField.reference.endpoint,
      query: { fechaInventario: fieldValue }
    });

    let materialData = [];
    let zonaDeTransporteData = [];

    if (material) {
      materialData = await getUncatchEndpointData({
        endpoint: material.reference.endpoint,
        query: { fechaInventario: fieldValue }
      });
      updateFetchedFields({ [material.selector]: { data: materialData } });
    }

    if (zonaDeTransporte) {
      zonaDeTransporteData = await getUncatchEndpointData({
        endpoint: zonaDeTransporte.reference.endpoint,
        query: { fechaInventario: fieldValue }
      });
      updateFetchedFields({ [zonaDeTransporte.selector]: { data: zonaDeTransporteData } });
    }
    updateFetchedFields({
      [zona.selector]: { data: zonaData }
    });
    updateFetchedFields({
      [numeroAntiguoMaterialField.selector]: { data: numeroAntiguoMaterialData }
    });
    updateFetchedFields({ [textoBreveMaterialField.selector]: { data: textoBreveMaterialData } });

    setLoadings([numeroAntiguoMaterialField, textoBreveMaterialField], false);
  } catch (error) {
    console.log(error);
  }
};

export const updateFechaDocumentoDependency: UpdateDependencyFunc = async (
  fieldValue,
  getField,
  setLoadings,
  updateFetchedFields,
  _,
  __
): Promise<void> => {
  try {
    const zonaDeTransporte = getField('zonaDeTransporte');
    setLoadings([zonaDeTransporte], true);

    if (zonaDeTransporte) {
      const zonaDeTransporteData = await getUncatchEndpointData({
        endpoint: zonaDeTransporte.reference.endpoint,
        query: { fechaDocumento: fieldValue }
      });
      updateFetchedFields({ [zonaDeTransporte.selector]: { data: zonaDeTransporteData } });
    }
    setLoadings([zonaDeTransporte], false);
  } catch (error) {
    console.log(error);
  }
};
