import React, { FunctionComponent } from 'react';
import { GenericForm } from './GenericForm';
import GenericModal from './GenericModal';

type GenericFilterFormProps = {
  filterFormFields: any;
  isAdmin: boolean;
  onSubmit: (formFields: any) => void;
};

// Table data Filter (Fetch new data from DB)
export const GenericFilterFormModal: FunctionComponent<GenericFilterFormProps> = ({
  filterFormFields,
  isAdmin,
  onSubmit
}) => {
  const renderFilter = (closeModal: () => void) => {
    const localSubmit = (formFields: any) => {
      onSubmit(formFields);
      closeModal();
    };
    return (
      <GenericFilterForm
        filterFormFields={filterFormFields}
        onSubmit={localSubmit}
        isAdmin={isAdmin}
      />
    );
  };
  return (
    <GenericModal
      title='Filtros'
      buttonName='Filtrar'
      localIcon={true}
      icon='filter'
      style={{
        display: 'flex',
        padding: '5px 15px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        border: ' 1px solid var(--ccu-verde-oscuro, #205C40)',
        background: 'var(--ccu-verde-oscuro, #205C40)',
        color: 'var(--blanco, #FFF)',
        textAlign: 'center',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px'
      }}
      children={renderFilter}
    />
  );
};

const GenericFilterForm: FunctionComponent<GenericFilterFormProps> = ({
  filterFormFields,
  onSubmit,
  isAdmin
}) => {
  const localOnSubmit = (e: Event, formFields: any) => {
    e.preventDefault();
    onSubmit(formFields);
  };

  return (
    <>
      <GenericForm
        {...{
          fields: filterFormFields,
          onSubmit: localOnSubmit,
          isAdmin: isAdmin,
          actionName: 'Filtrar'
        }}
      />
    </>
  );
};

export default GenericFilterForm;
