import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Label } from 'semantic-ui-react';
import {
  ingresarInventarioCriticidad,
  fetchDocumentInventarioCriticidad
} from '../actions/middleActions';
import { SingleEntityState } from '../reducers/types';
import { downloadXLSX } from '../utils/export';
import { getEntity, performUncatchedRequest, getUncatchEndpointData } from '../utils/request';
import {
  ReporteCategoriaConteoCantidad,
  ReporteCategoriaConteoMonto,
  ReporteZonaConteoCantidad,
  ReporteSKUCantidad,
  ReporteSKUMonto,
  ReporteVencimientoConteoCantidad,
  ReporteVencimientoConteoMonto,
  ReporteInventarioCriticidad,
  ReporteConteoCriticidadLog,
  ReporteInconsistenciaFechaLog
} from '../components/GeneratedComponents';
import { Documento } from '../types';
import { DatePickerComponent, GenericDropDownList } from '../components/generics/GenericForm';
import { AppState } from '../store';
import { errorPopAlert, requestErrorPopAlert } from '../components/PopAlert';
import {
  InventarioCriticidadDependent,
  DocumentoCriticidadVisualizer
} from '../components/GeneratedComponents';
import { cleanTextDate, modificarOrdenColumnas } from '../utils/utils';
import { AuthState } from '../reducers/types';
import { TipoUsuarioValues } from '../types';
import FileReader from '../components/FileReader';
import { Inventario } from '../types';
import {
  RefreshConteoCriticidadLogButton,
  RefreshReporteInconsistenciaFechaLogButton
} from '../components/SecondaryButton';
import { NameConteoValues } from '../types';
import GenericModal from '../components/generics/GenericModal';
import {
  CCUThemeButtonCargarConteo,
  CCUThemeButtonCargarInventario
} from '../components/generics/GenericThemes';
//import LogText from '../components/utils/LogText';

const COLUMNS = {
  idDocumento: { name: 'Id documento', required: false, export: true },
  bodega: 'Bodega',
  idCentro: 'Centro',
  almacen: 'Almacen',
  material: 'Material',
  lote: { name: 'Lote', required: false, export: true },
  fechaRegistro: 'Fecha registro',
  fechaProduccion: 'Fecha produccion',
  fechaVencimiento: 'Fecha vencimiento',
  cantidadContada: 'Cantidad contada',
  unidadCantidadContada: 'Unidad cantidad contada',
  tipoStock: { name: 'Tipo stock', required: false, export: true },
  pedidoCliente: { name: 'Pedido cliente', required: false, export: true },
  numeroHu: { name: 'Numero hu', required: false, export: true },
  usuario: { name: 'Usuario', required: false, export: true }
};

const columnsConteo: Record<string, string> = {
  numeroAntiguoMaterial: 'Sku Venta',
  marca: 'Marca',
  cantidadContada: 'Stock Total',
  textoBreveMaterial: 'Descr Producto',
  fechaInventario: 'Fecha Inv',
  fechaElaboracion: 'Fecha Elaboración',
  material: 'SKU Inventario',
  nombreCentro: 'Centro',
  zona: 'Zona',
  fechaVencimiento: 'Fecha_Venc',
  periodoVencer: 'Periodo a Vencer',
  vidaUtil: 'Vida Útil',
  diasPorVencer: 'Días Por Vencer',
  diasDesdeElaboracion: 'Dias desde Elaboracion',
  estadoVencido: 'Vencido?',
  categoriaConteo: 'Categoria Conteo',
  grupoProducto: 'DESCR_GRUPO_PROD',
  tipoProducto: 'AGRU_PROD',
  costoUnitario: 'COSTO',
  factor: 'Factor',
  montoFinal: 'Monto Final',
  cantidadVencer: 'Cantidad por vencer',
  promoVentaMes: 'Prom Venta',
  montoFinalVencer: 'Monto Final Vencer',
  vencera: 'Vencera?',
  vencePerfilEnvejecimiento: 'Vence PerfilEnvejecimiento',
  idCentro: 'ID Centro'
};

type Props = {
  documentoCriticidadState: SingleEntityState<Documento>;
  fetchDocumentInventarioCriticidad: any;
};

interface IProps {
  auth: AuthState;
}

const RefresherComponent: FunctionComponent<Props> = ({
  documentoCriticidadState,
  fetchDocumentInventarioCriticidad
}): any => {
  useEffect(() => {
    const update = async () => {
      if (documentoCriticidadState.singleSelected?._id) {
        await fetchDocumentInventarioCriticidad(documentoCriticidadState.singleSelected?._id);
      }
    };
    update();
  }, [documentoCriticidadState.singleSelected?._id, fetchDocumentInventarioCriticidad]);

  return <></>;
};

const ConnectedRefresh = connect(
  ({ entities: { documentoCriticidad } }: AppState) => ({
    documentoCriticidadState: documentoCriticidad
  }),
  {
    fetchDocumentInventarioCriticidad
  }
)(RefresherComponent);

const GenerarConteo: FunctionComponent = () => {
  const [initialDate, setInitialDate] = React.useState<string | null>(cleanTextDate(new Date()));
  const [isLoading, setIsLoading] = React.useState<boolean | null>(null);
  const [isExporting, setIsExporting] = React.useState<boolean | null>(null);
  const consolidado = true;
  //const [resultGenerateConteo, setResultGenerateConteo] = React.useState<string>('');

  const onDescargarReporteClick =
    (initialDate: string | null, consolidado: boolean) => async () => {
      try {
        if (!initialDate) {
          alert('Debes seleccionar una fecha');
          return;
        }

        setIsExporting(true);
        const data = await getUncatchEndpointData({
          endpoint: 'inventarioCriticidad/export/reporte',
          query: { initialDate, consolidado }
        });

        const newBlob = modificarOrdenColumnas(data, columnsConteo);

        if (!newBlob.size) {
          setIsExporting(false);
          requestErrorPopAlert({
            response: {
              data: { msg: 'No se encontraron registros en el rango de fecha especificado' }
            }
          });
          return;
        }
        downloadXLSX(newBlob);
        setIsExporting(false);
      } catch (error) {
        console.log(error);
        requestErrorPopAlert(error);
        setIsExporting(false);
      }
    };

  const onGenerarConteoClick = (initialDate: string | null) => async () => {
    try {
      if (!initialDate) {
        alert('Debes seleccionar una fecha');
        return;
      }

      setIsLoading(true);
      //const res =
      await performUncatchedRequest({
        endpoint: 'inventarioCriticidad/generarConteo',
        method: 'post',
        query: { fechaConteo: initialDate }
      });

      //if (res?.data?.result) {setResultGenerateConteo(JSON.stringify(res.data.result).replaceAll(',', '\n'));}

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      requestErrorPopAlert(error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className='pop-up-container'>
        <div className='pop-up-container-title'>
          <h3 className='pop-up-generar-conteo-title'>
            GENERAR <strong className='pop-up-generar-conteo-title-strong'>REPORTE</strong>
          </h3>
        </div>
        <div className='pop-up-container-date'>
          <label className='pop-up-date-title' style={{ width: '100%' }}>
            Fecha Conteo
          </label>
          <DatePickerComponent
            name={'Fecha Inicio'}
            value={initialDate}
            onChange={setInitialDate}
          />
        </div>

        <button className='btn-pop-up-generic' onClick={onGenerarConteoClick(initialDate)}>
          Cargar Reporte
        </button>
        <button
          className='btn-pop-up-generic'
          onClick={onDescargarReporteClick(initialDate, !consolidado)}>
          Exportar
        </button>
        {isLoading === true && <span className='loader'></span>}
        {isLoading === false && <h1>Generado!</h1>}
        {isExporting === true && <h1>Exportando...</h1>}
        {/* {isLoading === false && resultGenerateConteo && <LogText>{resultGenerateConteo}</LogText>} */}
      </div>
    </div>
  );
};

const WelcomePage: FunctionComponent<
  IProps & {
    ingresarInventarioCriticidad: (
      inventarioCriticidad: Array<Inventario>,
      date: Date,
      zona: string
    ) => Promise<any>;
  }
> = ({ ingresarInventarioCriticidad, auth }) => {
  const [date, setDate] = React.useState<string | null>(cleanTextDate(new Date()));
  const [selectedZone, setSelectedZone] = useState<string>('');
  const [zones, setZones] = useState<string[]>([]);
  const { user } = auth;
  const allowedUserTypes = [
    TipoUsuarioValues.Admin as string,
    TipoUsuarioValues.Operador as string
  ];

  const CargaInventarioBody = () => {
    if (allowedUserTypes.includes(user?.nombreTipoUsuario || '')) {
      return (
        <div>
          <GenericModal
            title='Carga de Inventario'
            style={CCUThemeButtonCargarInventario}
            buttonName='CARGAR CONTEO MASIVO'
            icon='update'
            localIcon={true}>
            <div>
              <Label>Fecha de Recuento</Label>
              <DatePickerComponent name={'Fecha'} value={date} onChange={setDate} />
              <div style={{ marginLeft: '1em', display: 'inline-block' }}>
                <Label>Zona</Label>
                <div style={{ display: 'inline-block' }}>
                  <GenericDropDownList
                    fieldName={'zone'}
                    data={zones}
                    onChange={onChangeZone}
                    valueField={'nombreCentro'}
                    textField={'nombreCentro'}
                  />
                </div>
              </div>
              <div>
                <Label>Archivo CSV</Label>
                <FileReader
                  columns={COLUMNS}
                  callback={callback(date)}
                  dataInputName={NameConteoValues.conteoCriticidad}
                />
              </div>
            </div>
          </GenericModal>
        </div>
      );
    }
    return null;
  };

  const onChangeZone = (_: any, data: any) => {
    setSelectedZone(data.value);
  };

  const callback = (date: string | null) => async (data: any) => {
    if (!selectedZone) {
      errorPopAlert('Debes seleccionar una zona');
      return;
    } else if (!data) {
      errorPopAlert('Debes cargar un documento de conteo');
      return;
    } else if (!date) {
      errorPopAlert('Debes seleccionar una fecha');
      return;
    }

    const res = await ingresarInventarioCriticidad(data, new Date(date), selectedZone);

    if (res.status === 200) {
      alert(`Se registro con éxito el documento con ID: ${res.data?.idDocumento}`);
    }
  };

  useEffect(() => {
    (async () => {
      setZones(await getEntity('centroInventario/zonas'));
    })();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: 20
      }}>
      <ConnectedRefresh />
      <h2 className='Conteo-Criticidad-title'>
        Conteo <strong className='Conteo-Criticidad-title-strong'>Criticidad</strong>
      </h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ display: 'flex', gap: 10 }}>
          {CargaInventarioBody()}
          <GenericModal
            buttonName='GENERAR REPORTE'
            style={CCUThemeButtonCargarConteo}
            localIcon={true}
            icon='table'
            modelstyle={{
              width: 331,
              height: 267,
              top: 'auto',
              left: 'auto',
              overflow: 'visible'
            }}>
            <GenerarConteo />
          </GenericModal>
        </div>

        <div
          style={{
            backgroundColor: ' red',
            borderRadius: '5px',
            border: ' 2px solid var(--gray-5, #E0E0E0)',
            background: 'var(--blanco, #FFF)',
            boxShadow: '4px 5px 7px 0px #D5D6D7',
            padding: 20,
            width: '100%'
          }}>
          <DocumentoCriticidadVisualizer />
        </div>
        <div
          style={{
            backgroundColor: ' red',
            borderRadius: '5px',
            border: ' 2px solid var(--gray-5, #64A70B)',
            background: 'var(--blanco, #FFF)',
            boxShadow: '4px 5px 7px 0px #D5D6D7',
            padding: 20,
            width: '100%'
          }}>
          <InventarioCriticidadDependent />
        </div>
      </div>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }), { ingresarInventarioCriticidad })(
  WelcomePage
);

/* REPORTES GENERALES */

export const ReporteCategoriaConteoMontoComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteCategoriaConteoMonto />
    </div>
  );
};

export const ReporteCategoriaConteoCantidadComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteCategoriaConteoCantidad />
    </div>
  );
};

export const ReporteZonaConteoMontoComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteCategoriaConteoCantidad />
    </div>
  );
};

export const ReporteZonaConteoCantidadComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteZonaConteoCantidad />
    </div>
  );
};

export const ReporteSKUMontoComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteSKUMonto />
    </div>
  );
};
export const ReporteSKUCantidadComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteSKUCantidad />
    </div>
  );
};

export const ReporteVencimientoConteoMontoComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteVencimientoConteoMonto />
    </div>
  );
};

export const ReporteVencimientoConteoCantidadComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteVencimientoConteoCantidad />
    </div>
  );
};

export const ReporteInventarioCriticidadComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteInventarioCriticidad />
    </div>
  );
};

/* REPORTES GENERALES */

/* REPORTES INCONSISTENCIAS */

export const ReporteConteoCriticidadLogComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1em' }}>
        <RefreshConteoCriticidadLogButton />
      </div>
      <ReporteConteoCriticidadLog />
    </div>
  );
};

export const ReporteInconsistenciaFechaLogComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1em' }}>
        <RefreshReporteInconsistenciaFechaLogButton />
      </div>
      <ReporteInconsistenciaFechaLog />
    </div>
  );
};

/* REPORTES INCONSISTENCIAS */
