import { CLEAR_ERRORS, GET_ERRORS } from '../reducers/types';

export const returnErrors = (
  msg: any,
  status: number,
  id: string | null = null
): { type: typeof GET_ERRORS; payload: { msg: any; status: number; id: string | null } } => {
  return {
    type: GET_ERRORS,
    payload: { msg, status, id }
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
