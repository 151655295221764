import React, { FunctionComponent, ReactNode, useState, CSSProperties } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonProps, Icon } from 'semantic-ui-react';
import { Documento } from '../types';
import { getUncatchEndpointData } from '../utils/request';
import { addObjectToStorage, removeFromStorage } from '../utils/storage';
import { Fields, WebEntityName } from '../entities/types';
import { popAlert, PopAlertType } from './PopAlert';
import {
  refreshConteoCriticidadLog,
  refreshReporteInconsistenciaFechaLog,
  cleanSelectedEntities
} from '../actions/middleActions';

type ButtonParams = {
  action: (params?: any) => Promise<void>;
  selected: any[];
};

type Props = {
  action: (params?: any) => Promise<void>;
  onClick: (buttonParams: ButtonParams) => Promise<void>;
  selected?: any[];
  btnProps: ButtonProps;
  children: ReactNode;
  style?: CSSProperties;
};

const SecondaryButton: FunctionComponent<Props> = ({
  action,
  onClick,
  selected = [],
  btnProps,
  children,
  style
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnClick = async () => {
    setLoading(true);
    await onClick({ action, selected });
    setLoading(false);
  };

  return (
    <>
      <Button
        {...{
          ...{
            key: 'secondary',
            style: { marginRight: '1em', ...(style || {}) },
            color: 'orange',
            icon: false
          },
          ...btnProps,
          onClick: handleOnClick,
          loading
        }}>
        {children}
      </Button>
    </>
  );
};

const connectedSecondaryButton = (action: any) => connect(null, { action })(SecondaryButton);

/* const genericOnClick = async (buttonParams: ButtonParams) => {
 *   const { action, selected } = buttonParams;
 *   await action(selected.map((s: any) => s._id));
 * }; */

/* export const exampleButton = ({
 *   selected,
 *   refreshStatus
 * }: {
 *   selected: TypeOfSelected[];
 *   refreshStatus: TypeOfStatus;
 * }) => {
 *   const ConnectedBtn = connectedSecondaryButton(actionToPerform(refreshStatus));
 *
 *   return (
 *     <ConnectedBtn
 *       onClick={genericOnClick}
 *       selected={selected}
 *       btnProps={{ basic: true, color: 'orange', inverted: true }}>
 *       <Icon name='undo' />
 *       Deshacer
 *     </ConnectedBtn>
 *   );
 * }; */

export const RefreshConteoCriticidadLogButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshConteoCriticidadLog);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'blue' }}>
      <Icon name='refresh' />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshReporteInconsistenciaFechaLogButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshReporteInconsistenciaFechaLog);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'blue' }}>
      <Icon name='refresh' />
      Refrescar
    </ConnectedBtn>
  );
};

export const DuplicateDocMultiForm = ({
  selected,
  endpoint,
  endpointNameMultiInput,
  fields,
  entityName
}: {
  selected: Array<Documento>;
  endpoint: string;
  endpointNameMultiInput: string;
  fields: Fields[];
  entityName: WebEntityName;
}) => {
  const documentoId = selected.map((item) => item._id);
  const ConnectedBtn = connectedSecondaryButton(cleanSelectedEntities);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    if (documentoId.length > 1) {
      popAlert({
        type: PopAlertType.ERROR,
        title: `Accion no permitida`,
        details: `No se permite duplicar mas de un conteo`
      });
      return;
    }

    const documentData = await getUncatchEndpointData({
      endpoint: `${endpointNameMultiInput}/getDocumentById`,
      query: {
        documentoId: documentoId
      }
    });

    const conteoData = await getUncatchEndpointData({
      endpoint: `${endpoint}/getDataByDocumentId`,
      query: {
        documentoId: documentoId
      }
    });

    const centro = await getUncatchEndpointData({
      endpoint: `centroInventario/nombreCentro`,
      query: {
        zonaDeTransporte: conteoData[0].bodega
      }
    });

    documentData.zonaDeTransporte = centro.nombreCentro;

    removeFromStorage(endpointNameMultiInput);
    const multiInputFields = [];
    const state: Record<string, string> = {};
    const headerFields = fields.filter((f: any) => !f.multiInput?.multiField);
    const multiFields = fields.filter((f: any) => f.multiInput?.multiField);

    headerFields
      .filter((field) => field.multiInput?.isDuplicable)
      .map((field) => {
        state[field.selector] = documentData[field.selector];
      });

    for (let i = 0; i < conteoData.length; i++) {
      multiInputFields.push(multiFields);

      multiFields
        .filter((field) => field.multiInput?.isDuplicable)
        .map((field) => {
          state[`${field.selector}_${i}`] = conteoData[i][field.selector];
        });
    }
    await addObjectToStorage(endpointNameMultiInput, {
      multiInputFields: multiInputFields,
      state: state
    });

    await action(entityName);

    const btn = document.getElementById(endpointNameMultiInput) as HTMLButtonElement;
    if (btn) {
      btn.click();
    }
  };

  return (
    <ConnectedBtn
      onClick={onClick}
      children='Duplicar'
      btnProps={{ basic: false, color: 'blue' }}
    />
  );
};
